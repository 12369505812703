import React from "react";
import { Input } from "antd";
import { styled } from "styled-components";
const { Search } = Input;

const HeadingSearch = ({ Icon, fill, title, onSearch, placeholder }) => {

  const handleChange = (e) => {
    // Call onSearch function with the current value
    if (!!e?.target?.value) {
      onSearch(e?.target?.value);
    } else {
      onSearch();
    }
  };

  return (
    <div className="flex items-center gap-8">
      <div className="flex items-center justify-between gap-4">
        <span>
          <Icon
            fill={fill || "none"}
            size={40}
          />
        </span>
        <h1 className="text-4xl font-semibold">{title}</h1>
      </div>
      <div className="flex justify-center items-center w-full !text-font_color_2">
        <SearchBar
          className="max-w-xl"
          placeholder={`search ${placeholder || ""}`}
          size="large"
          enterButton
          onChange={handleChange}
          onSearch={onSearch}
          $fontColor={"#0A0A0A"}
          $bgColor={"#CECECE"}
        />
      </div>
    </div>
  );
};

export default HeadingSearch;

const SearchBar = styled(Search)`
  .ant-input {
    font-size: 1.25rem;
    border-start-start-radius: 20px;
    border-end-start-radius: 20px;
    color: ${({ $fontColor }) => $fontColor};
    background-color: ${({ $bgColor }) => $bgColor};
    border: none;

    &:hover {
      border: none;
    }

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }

    &::placeholder {
      color: ${({ $fontColor }) => $fontColor};
    }
  }

  .ant-input-search-button {
    border-start-end-radius: 20px !important;
    border-end-end-radius: 20px !important;
    background-color: ${({ $bgColor }) => $bgColor};
    height: 44px;

    .ant-btn-icon span svg {
      fill: ${({ $fontColor }) => $fontColor};
      width: auto;
      height: 1.5rem;
    }
  }

  .ant-input-search-button:not(:disabled):hover {
    background-color: ${({ $bgColor }) => $bgColor};
  }
`;
