/**
 * The `LoggedRouter` component checks if a user is logged in and redirects them to the home page if
 * they are.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "helper/Auth";
import { routesConfig } from "helper/Config";

/**
 * The `LoggedRouter` component checks if a user is logged in and redirects them to the home page if
 * they are.
 * @returns The `LoggedRouter` component is returning the `Component` that is passed as a prop.
 */
export const LoggedRouter = ({ Component }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (Auth?.getToken()) {
      return navigate(routesConfig?.dashboard);
    }
  }, [navigate]);

  return <Component />;
};
