/* The code is setting up the Redux store for a JavaScript application. */
import { applyMiddleware, combineReducers, createStore } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import * as reducer_1 from "redux_manager/reducer";

/* The `combineReducers` function is used to combine multiple reducers into a single reducer function.
In this code, it is combining several reducers related to different parts of the application's state
into one reducer object. */
const reducer = combineReducers({
  // Admin Reducer
  adminAuth: reducer_1.adminReducer,

  // User Reducer
  users: reducer_1.userReducer,

  // Content Reducer
  content: reducer_1.contentReducer,

  // Feedback Reducer
  feedbacks: reducer_1.feedbackReducer,

  // Reports Reducer
  reports: reducer_1.reportReducer,

  // Flight-sim-game Reducer
  flightSimGame: reducer_1.flightSimGameReducer,

  // Flight-sim-game Reducer
  homeAirport: reducer_1.homeAirportReducer,
});

/* The line `const middleware = [thunk];` is creating an array called `middleware` and assigning it the
value `[thunk]`. */
const middleware = [thunk];

/* The code is creating the main Redux store using the `createStore` function from the Redux library. */
const store = createStore(
  reducer,
  /* `composeWithDevTools(applyMiddleware(...middleware))` is a function that enhances the Redux store
  with additional debugging capabilities. */
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
