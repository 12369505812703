/**
 * The code defines two React components, `PrivateRouter` and `UsedRouted`, that handle authentication
 * and redirect users to the login page if they are not authenticated.
 * @returns The PrivateRouter component returns the Component passed as a prop, while the UsedRouted
 * component also returns the Component passed as a prop.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "helper/Auth";
import { routesConfig } from "helper/Config";

/**
 * The PrivateRouter component is used to protect routes and redirect users to the login page if they
 * are not authenticated.
 * @returns The PrivateRouter component is returning the Component that is passed as a prop.
 */
export const PrivateRouter = ({ Component }) => {
  const navigate = useNavigate();

  // console.log("🚀 ~ file: PrivateRouter.jsx:22 ~ Auth?.getToken():", Auth?.getToken());
  React.useEffect(() => {
    if (!Auth?.getToken()) {
      return navigate(routesConfig?.login);
    }
  }, [navigate]);

  return <Component />;
};
