/* This code is importing the `axios` library and creating an instance of it called `UserAuthAPI`. */
import axios from "axios";
import { API_CONFIG } from "./API.config";

/* The line `export const BASEURL = API_CONFIG;` is exporting a constant variable called `BASEURL` that
is assigned the value of `API_CONFIG`. The `API_CONFIG` variable is likely defined in the
`API.config` file and contains the base URL for the API. By exporting `BASEURL`, it can be imported
and used in other files to construct the base URL for different API endpoints. */
export const BASEURL = API_CONFIG;

export const AdminAuthAPI = axios.create({
  baseURL: `${BASEURL}/auth`,
});

export const AdminAPI = axios.create({
  baseURL: BASEURL,
});

export const UserAPI = axios.create({
  baseURL: `${BASEURL}/users`,
});

export const ContentAPI = axios.create({
  baseURL: `${BASEURL}/post`,
});

export const FeedbackAPI = axios.create({
  baseURL: `${BASEURL}/user-feedback`,
});

export const ReportAPI = axios.create({
  baseURL: `${BASEURL}/report`,
});

export const FlightSimGameAPI = axios.create({
  baseURL: `${BASEURL}/flight-sim-game`,
});

export const HomeAirportAPI = axios.create({
  baseURL: `${BASEURL}/home-airport`,
});
