/* The code you provided is a JavaScript module that defines a userReducer function and exports it. The
userReducer function is a reducer function that handles different actions related to user data and
updates the state accordingly. */
import * as constants from "redux_manager/constants";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case constants.ADMIN_SUCCESS:
      return {
        ...state,
        user: action?.payload,
        loading: false,
      };

    case constants.ADMIN_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    case constants.ADMIN_LOGOUT:
      return {
        ...state,
        loading: false,
        user: null,
      };

    default:
      return state;
  }
};
