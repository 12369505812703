import React from "react";
import { ConfigProvider, Modal, theme } from "antd";
import { Heading } from "components";
import { X } from "lucide-react";

const ModelWrapper = ({ open, onCancel, title, children }) => {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          // colorPrimary: "white",
        },
        components: {
          Modal: {
            colorText: "white",
            contentBg: "#1f2937",
          },
        },
      }}>
      <Modal
        open={open}
        centered
        closeIcon={false}
        onCancel={onCancel}
        footer={null}>
        <div className="flex gap-2 justify-between items-center mb-4">
          <Heading
            title={title}
            className={"!text-2xl"}
          />
          <button
            className="p-1 transition-all border-2 border-transparent hover:text-red-600 hover:border-red-600 rounded-full"
            onClick={onCancel}>
            <X size={25} />
          </button>
        </div>
        {children}
      </Modal>
    </ConfigProvider>
  );
};

export default ModelWrapper;
