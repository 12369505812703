import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Trash } from "lucide-react";
import { Tooltip } from "antd";

const DeleteButton = ({ className }) => {
  const [arrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <Tooltip
      placement="top"
      title={"Delete"}
      arrow={mergedArrow}>
      <Delete
        size={20}
        role="button"
        className={`${className || ""}`}
        // title={"Delete"}
      />
    </Tooltip>
  );
};

export default DeleteButton;

const Delete = styled(Trash)`
  ${"" /* cursor: pointer; */}
  &:hover {
    color: #ef3e55;
  }
`;
