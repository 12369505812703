/* The code snippet defines a JavaScript object called `routesConfig` which contains various paths for
different pages or functionalities of a website. */
const homePath = "/";
const id = homePath + ":id";

// routesConfig
// SEO configuration
export const routesConfig = {
  // homepage
  homePath: homePath,

  // authentication configuration
  login: homePath + "login",

  // dashboard
  dashboard: homePath + "dashboard",

  // users
  users: homePath + "users",

  // user details
  userDetails: homePath + "user-details",
  userDetailsId: homePath + "user-details" + id,

  // content
  content: homePath + "content",

  // content
  feedbacks: homePath + "feedbacks",

  // report
  reports: homePath + "reports",

  // report details
  reportDetail: homePath + "report-details",
  reportDetailId: homePath + "report-details" + id,

  // flight sim game
  flightSimGame: homePath + "flight-sim-game",

  // flight sim game
  homeAirport: homePath + "home-airport",

  // error
  error: "*",
};
