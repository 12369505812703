/* The code you provided is a JavaScript module that defines a userReducer function and exports it. The
userReducer function is a reducer function that handles different actions related to user data and
updates the state accordingly. */
// import * as constants from "redux/constants";
import { GET_ALL_FEEDBACK_REQUEST, GET_ALL_FEEDBACK_SUCCESS, GET_ALL_FEEDBACK_FAILURE } from "redux_manager/constants";

const initialState = {
  feedbackList: [],
  loading: false,
  error: null,
};

export const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FEEDBACK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackList: action?.payload,
        loading: false,
      };

    case GET_ALL_FEEDBACK_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
