/**
 * The ScrollToTop component is a React component that scrolls the window to the top whenever the
 * location changes.
 * @returns The ScrollToTop component returns the children prop.
 */
import React from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  /* The `React.useEffect` hook is used to perform side effects in a functional component. In this case,
 it is used to scroll the window to the top whenever the `navigate` variable changes. */
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  /* The `return children;` statement is returning the `children` prop of the `ScrollToTop` component.
  This means that any components or elements that are passed as children to the `ScrollToTop`
  component will be rendered and displayed. */
  return children;
};

export default ScrollToTop;
