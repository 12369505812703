/* The code snippet is defining an object named `message` that contains various string constants. These
constants are used for displaying messages in different scenarios in a JavaScript application. */

const YUP_REQUIRED_MESSAGE = "Required!";

/* The code snippet is defining an object named `message` that contains various string constants. These
constants are used for displaying messages in different scenarios in a JavaScript application. Each
constant represents a specific message that can be used for error handling, validation, or user
interface feedback. The `message` object is exported using the `export` keyword, which allows other
modules to import and use these message constants. */
export const message = {
  // ERROR messages
  NETWORK_ERROR: "No internet connection Cannot connect to the server, Check your internet network",
  ERROR_TRY_AGAIN: "Something went wrong! Try again in a few minutes",
  LOGIN_ADMIN_INVALID_EMAIL: "Invalid email Please check your credentials.",
  LOGIN_ADMIN_INVALID_EMAIL_OR_PASSWORD: "Invalid email or password Please check your credentials.",

  // validation Messages
  YUP_REQUIRED: YUP_REQUIRED_MESSAGE,
  YUP_EMAIL: "Invalid email",
  YUP_TOO_SHORT: "Too Short!",
  YUP_TOO_LONG: "Too Long!",
  YUP_INVALID_NUMBER: "Invalid mobile number",
  YUP_PASSWORD_MIN: "Password must be at least 8 characters",
  YUP_PASSWORD_MAX: "Password can be at most 50 characters",
  YUP_PASSWORD_NOT_MATCH: "Password does not match",
  YUP_MUST_AGREE_TO_PRIVACY_POLICY: "You must agree to the Privacy Policy",

  // Sign In
  EMAIL_REQUIRED: `Email must be provided!`,

  // User
  FIRST_NAME: `First Name is ${YUP_REQUIRED_MESSAGE}`,
  LAST_NAME: `Last Name is ${YUP_REQUIRED_MESSAGE}`,

  // SUCCESS
  PRESCRIPTION_SUBMITTED_SUCCESS: "Prescription Submitted successfully!",
  RESEND_OTP_SUCCESS: "Verified code was resent successfully!",
  PROFILE_UPDATE_SUCCESS: "User profile was updated successfully!",
  LOGIN_SUCCESS: "You are logged in successfully!",
  LOGGED_OUT_SUCCESS: "You have successfully logged out of the application",
  COPY_CLIPBOARD: "Referral code copied to clipboard.",
  CART_ADD_SUCCESS: "Product was successfully added into cart",

  // FAILURE

  IS_LOADING: "Loading...",
};
