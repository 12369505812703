import { ContentAPI, ReportAPI, getHeaders } from "helper/API";
import { toastMessage } from "helper/Config";
import { GET_ALL_REPORT_REQUEST, GET_ALL_REPORT_SUCCESS, GET_ALL_REPORT_FAILURE } from "../constants";
import { ErrorToast } from "helper/Toast";

export const listAllReports = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_REPORT_REQUEST });

      // API
      const response = await ReportAPI.get("/", { headers });
      // console.log("🚀 ~ file: reportAction.js:16 ~ return newPromise ~ response:", response);

      const reportList = [];
      response?.data?.forEach((x, index) => {
        reportList.push({
          ...x,
          id: x?._id,
          index: index + 1,
        });
      });

      dispatch({
        type: GET_ALL_REPORT_SUCCESS,
        payload: reportList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_REPORT_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const listSearchReports = (query) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {};
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_REPORT_REQUEST });

      // API
      const response = await ContentAPI.post(`/search-feed?searchQuery=${query}`, body, { headers });
      // console.log("🚀 ~ file: reportAction.js:16 ~ return listSearchReports ~ response:", response);

      const reportList = [];
      response?.data?.report?.forEach((x) => {
        reportList.push({
          ...x,
          id: x?._id,
        });
      });

      dispatch({
        type: GET_ALL_REPORT_SUCCESS,
        payload: reportList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_REPORT_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};
