/* The code block you provided is defining a set of constants for flight-sim-game related actions in a JavaScript
application. Each constant represents a specific action that can be dispatched in the application. */

// FLIGHT-SIM-GAME CONSTANTS //

// GET ALL HOME_AIRPORT
export const GET_ALL_HOME_AIRPORT_REQUEST = "GET_ALL_HOME_AIRPORT_REQUEST";
export const GET_ALL_HOME_AIRPORT_SUCCESS = "GET_ALL_HOME_AIRPORT_SUCCESS";
export const GET_ALL_HOME_AIRPORT_FAILURE = "GET_ALL_HOME_AIRPORT_FAILURE";

// CREATE HOME_AIRPORT
export const CREATE_HOME_AIRPORT = "CREATE_HOME_AIRPORT";

// UPDATE HOME_AIRPORT
export const UPDATE_HOME_AIRPORT = "UPDATE_HOME_AIRPORT";

// DELETE HOME_AIRPORT
export const DELETE_HOME_AIRPORT = "DELETE_HOME_AIRPORT";
