/* The Auth class is used to authorize a admin and save a token string in cookies. */
// For get item use @returns {type} || no need to returns then use @param {type}
import CryptoJS from "crypto-js";
import { setEncryptCookie, getCookie, removeCookie, setCookie } from "utils";
import { SECURITY_KEYS, STORAGE_KEYS } from "./APP";
import Cookies from "js-cookie";

/* The Auth class provides methods for setting the authentication email in local storage and returning
a specific role ID. */
export class Auth {
  /**
   * The function sets the admin's email as a cookie with a specified storage key and security key.
   * @param email - The email parameter is the email address of the admin that needs to be set.
   */
  static setAdminEmail(email) {
    setEncryptCookie(STORAGE_KEYS.email, email, SECURITY_KEYS?.email);
  }

  /**
   * The function `getAdminEmail()` retrieves and decrypts a cookie containing an email address using
   * AES encryption.
   * @returns the decrypted email value obtained from the cookie.
   */
  static getAdminEmail() {
    const getCookies = getCookie(STORAGE_KEYS?.email);

    const email = getCookies && CryptoJS.AES.decrypt(getCookies, SECURITY_KEYS?.email).toString(CryptoJS.enc.Utf8);

    return email;
  }

  /**
   * The function sets a token in a cookie with a specified name and security options.
   * @param token - The token parameter is the value that you want to set as the token. It could be a
   * string or any other data type that represents the token value.
   */
  static setToken(token) {
    setCookie(STORAGE_KEYS?.token, token);
  }

  /**
   * The function `getToken()` retrieves and decrypts a token stored in a cookie using AES encryption.
   * @returns the decrypted token value.
   */
  static getToken() {
    const token = getCookie(STORAGE_KEYS?.token);

    return token;
  }

  /**
   * The function sets the Admin ID as a cookie with a specified storage key and security key.
   * @param id - The id parameter is the Admin identification value that you want to set for the Admin.
   */
  static setAdminId(id) {
    setEncryptCookie(STORAGE_KEYS.admin_id, id, SECURITY_KEYS?.admin_id);
  }

  /**
   * The function `getAdminId` retrieves and decrypts a Admin ID stored in a cookie using AES encryption.
   * @returns the decrypted Admin ID.
   */
  static getAdminId() {
    const isAdminId = getCookie(STORAGE_KEYS?.admin_id);

    const id = isAdminId && CryptoJS.AES.decrypt(isAdminId, SECURITY_KEYS?.admin_id).toString(CryptoJS.enc.Utf8);

    return id;
  }

  /**
   * The function "roleAdmin" returns a specific role ID.
   * @returns the value of the variable `roleId`, which is the string "64b5200bcde1b39872064686".
   */
  static roleAdmin() {
    const roleId = process.env.REACT_APP_USER_ROLE_ID;
    return roleId;
  }

  /**
   * The function `adminLogout` removes certain cookies related to admin authentication and
   * identification.
   * @returns Nothing is being returned. The return statement is used to exit the function early, but
   * it does not specify a value to be returned.
   */
  static adminLogout() {
    // Get all existing cookies
    const cookies = Cookies.get();

    // Loop through cookies and remove each one
    Object.keys(cookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });

    localStorage.clear();

    return;
  }

  /**
   * The function `adminClear()` clears all existing cookies after a timeout of 1 day.
   * @returns Nothing is being returned. The return statement is used to exit the function early, but
   * since there is no value being returned, it is not necessary in this case.
   */
  static adminClear() {
    // Get all existing cookies
    const cookies = Cookies.get();

    // Loop through cookies and remove each one
    Object.keys(cookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });

    localStorage.clear();

    return;
  }

  static isLogin() {
    if (this.getAdminId()) {
      return true;
    }
    return false;
  }
}
