import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ArrowLeft } from "lucide-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { routesConfig } from "helper/Config";
import { TableWrapper, Heading, RenderCell, Loader, DateTimeFormat } from "components";
import { useDispatch, useSelector } from "react-redux";
import { listAllReports } from "redux_manager/actions";
import { EyeOutlined } from "@ant-design/icons";
import { ApiPostByIdAuthorization } from "helper/API";

const ReportDetails = () => {
  const { loading, reportList } = useSelector((state) => state.reports);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: reportId } = useParams();

  const columns = [
    {
      title: "Report ID",
      dataIndex: "index",
      key: "index",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<DateTimeFormat value={value} />}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "report_type",
      key: "report_type",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value === "post_report" ? "Post" : value === "account_report" ? "User" : "Empty"}
        />
      ),
    },
    // {
    //   title: "Reported User",
    //   dataIndex: "reported_user_account",
    //   key: "reported_user_account",
    //   align: "center",
    //   className: "text-xl border-r border-font_color_1",
    //   render: (value, record) => (
    //     <RenderCell
    //       loading={loading}
    //       content={<div>{value?.firstname ? `${value?.firstname} ${value?.lastname || ""}` : `${value?.username}`}</div>}
    //     />
    //   ),
    // },
    {
      title: "Reported Post",
      dataIndex: "reported_post_id",
      key: "reported_post_id",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value?._id ? value?._id : "Empty"}
        />
      ),
    },
    {
      title: "Reported By",
      dataIndex: "report_by",
      key: "report_by",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<div>{value?.firstname ? `${value?.firstname} ${value?.lastname || ""}` : `${value?.username}`}</div>}
        />
      ),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<div>{value}</div>}
        />
      ),
    },
    {
      title: "View",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      className: "text-xl",
      width: 70,
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={
            <LinkWrapper to={`${routesConfig.reportDetail}/${value}`}>
              <EyeOutlined />
            </LinkWrapper>
          }
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const fetchReportDetails = async () => {
    setIsLoading(true);
    try {
      const body = {};
      const response = await ApiPostByIdAuthorization("/report/report-hisory", reportId, body);
      // console.log("🚀 ~ file: UserDetails.jsx:167 ~ fetchReportDetails ~ response:", response);
      setReportDetails(response?.data?.reportList);
      const length = response?.data?.reportHistory?.length;
      setData(
        response?.data?.reportHistory?.map((x, index) => {
          return { ...x, index: index + 1 };
        })
      );

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: length,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("🚀 ~ file: UserDetails.jsx:185 ~ fetchReportDetails ~ error:", error);
    }
  };

  useEffect(() => {
    // API
    fetchReportDetails();
  }, [reportId, dispatch, JSON.stringify(tableParams)]);

  return (
    <div className="w-full p-16 text-white">
      <div className="mb-4">
        <button onClick={() => navigate(routesConfig.reports)}>
          <ArrowLeft size={40} />
        </button>
      </div>
      <div className="flex flex-col gap-10">
        {/* Heading*/}
        <div className="w-full">
          <Heading title={"Report Details"} />
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-10">
            {/* Report details */}
            <div className="bg-gradient-to-b from-[#303030] to-[#505050] rounded-xl">
              <div className="px-4 text-xl">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="font-medium leading-6">Report Id</dt>
                    <dd className="mt-1 leading-6 sm:col-span-2 sm:mt-0">{reportDetails?._id}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="font-medium leading-6">Message</dt>
                    <dd className="mt-1 leading-6 sm:col-span-2 sm:mt-0">{reportDetails?.message}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="font-medium leading-6">Type Of Report</dt>
                    <dd className="mt-1 leading-6 sm:col-span-2 sm:mt-0">
                      {reportDetails?.report_type === "post_report"
                        ? "Post"
                        : reportDetails?.report_type === "account_report"
                        ? "User"
                        : "Empty"}
                    </dd>
                  </div>
                  {reportDetails?.reported_post_id && (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="font-medium leading-6">Post</dt>
                      <dd className="mt-1 leading-6 sm:col-span-2 sm:mt-0">
                        <div className="">
                          {!!reportDetails?.reported_post_id?.images.length ? (
                            <img
                              className="max-w-80"
                              src={reportDetails?.reported_post_id?.images?.[0]}
                              alt="report_post"
                            />
                          ) : !!reportDetails?.reported_post_id?.videos.length ? (
                            <video
                              className="rounded-[4px] w-full max-w-80"
                              preload="metadata"
                              controls>
                              <source
                                src={reportDetails?.reported_post_id?.videos?.[0]}
                                type="video/mp4"
                                alt="report_post"
                              />
                            </video>
                          ) : (
                            "The post url is not valid so it is not displayed!"
                          )}
                        </div>
                      </dd>
                    </div>
                  )}

                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="font-medium leading-6">Reported User</dt>
                    <dd className="mt-1 leading-6 sm:col-span-2 sm:mt-0">
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-2">
                          <p className="w-[70px]">Id</p>
                          <span>:</span>
                          <p>{reportDetails?.reported_user_account?._id}</p>
                        </div>
                        <div className="flex gap-2">
                          <p className="w-[70px]">Name</p>
                          <span>:</span>
                          <p>
                            {reportDetails?.reported_user_account?.firstname
                              ? `${reportDetails?.reported_user_account?.firstname} ${reportDetails?.reported_user_account?.lastname || ""}`
                              : `${reportDetails?.reported_user_account?.username}`}
                          </p>
                        </div>
                      </div>
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="font-medium leading-6">Reported By</dt>
                    <dd className="mt-1 leading-6 sm:col-span-2 sm:mt-0">
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-2">
                          <p className="w-[70px]">Id</p>
                          <span>:</span>
                          <p>{reportDetails?.report_by?._id}</p>
                        </div>
                        <div className="flex gap-2">
                          <p className="w-[70px]">Name</p>
                          <span>:</span>
                          <p>
                            {reportDetails?.report_by?.firstname
                              ? `${reportDetails?.report_by?.firstname} ${reportDetails?.report_by?.lastname || ""}`
                              : `${reportDetails?.report_by?.username}`}
                          </p>
                        </div>
                      </div>
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="font-medium leading-6">Date & Time</dt>
                    <dd className="mt-1 leading-6 sm:col-span-2 sm:mt-0">
                      <DateTimeFormat value={reportDetails?.createdAt} />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            {/* Table */}
            <div className="flex flex-col gap-2">
              {/* Other Reports of user */}
              <div className="w-full mb-4">
                <Heading
                  title={"Other Reports Of This User"}
                  className={"!text-3xl"}
                />
              </div>
              {/* Table */}
              <div>
                <TableWrapper
                  columns={columns}
                  data={data}
                  tableParams={tableParams}
                  rowSelection={rowSelection}
                  handleTableChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportDetails;

const LinkWrapper = styled(Link)`
  &:hover {
    color: limeGreen;
  }
`;
