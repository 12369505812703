import React, { useEffect, useState } from "react";
import "@sweetalert2/theme-dark/dark.css";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EditButton,
  DeleteButton,
  Heading,
  RenderCell,
  TableWrapper,
  InputField,
  CustomCheckbox,
  SpinLoader,
  ModelWrapper,
  DateTimeFormat,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import { createFlightSimGame, deleteFlightSimGame, listAllFlightSimGame, updateFlightSimGame } from "redux_manager/actions";
import { ButtonWrapper, Form, FormBg } from "assets/styles";
import { flightSchema } from "helper";

const FlightSimGame = () => {
  const { loading, flightSimGameList } = useSelector((state) => state.flightSimGame);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFlightSimGameId, setSelectedFlightSimGameId] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const dispatch = useDispatch();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(flightSchema),
  });

  const onSubmit = async (values) => {
    try {
      const body = { game_name: values?.game_name, is_active: isActive };

      // Update API
      if (isEdit) {
        dispatch(updateFlightSimGame(body, selectedFlightSimGameId, handleClose));
        return;
      }

      // Create API
      dispatch(createFlightSimGame(body, handleClose));
      // Submit the form data here
    } catch (error) {
      console.log("🚀 ~ file: FlightSimGame.jsx:28 ~ onSubmit ~ error:", error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    reset();
    setIsActive(true);
    setSelectedFlightSimGameId(null);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    setSelectedFlightSimGameId(id);
    const flightSimGame = flightSimGameList.find((x) => x.id === id);
    setValue("game_name", flightSimGame?.game_name);
    setIsActive(flightSimGame?.is_active);
    setIsOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteFlightSimGame(id));
  };

  const handleSwalFire = async (id) => {
    const { value } = await Swal.fire({
      title: "Are you sure you want to delete?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef3e55",
      showCancelButton: true,
      customClass: { confirmButton: "!shadow-none box border-none" },
    });

    if (value) {
      handleDelete(id);
    }
  };

  const columns = [
    {
      title: "Sim Game ID",
      dataIndex: "index",
      key: "index",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "game_name",
      key: "game_name",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      width: 120,
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      className: "text-xl",

      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<DateTimeFormat value={value} />}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      className: "text-xl",
      fixed: "right",
      width: 120,
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={
            <div className="flex items-center justify-center gap-4">
              <button onClick={() => handleEdit(value)}>
                <EditButton />
              </button>

              <button onClick={() => handleSwalFire(value)}>
                <DeleteButton />
              </button>
            </div>
          }
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const fetchFlightSimGameApi = async () => {
    // API
    await dispatch(listAllFlightSimGame());
  };

  useEffect(() => {
    fetchFlightSimGameApi(); // Fetch initial data
  }, [flightSimGameList?.length]);

  useEffect(() => {
    const length = flightSimGameList?.length;
    setData(flightSimGameList);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: length,
      },
    });
  }, [flightSimGameList]);

  return (
    <div className="w-full p-16 text-white">
      <div className="flex flex-col gap-8">
        <div className="flex items-center gap-4">
          <Heading title={"Flight Sim Game"} />
          <ButtonWrapper
            className="focus:outline-none"
            type="button"
            $size="1.125"
            $radius="0.5"
            $color="#FFF"
            $bg_color="#234DF0"
            onClick={() => setIsOpen(true)}>
            Add Flight Sim Game
          </ButtonWrapper>

          {/* Model */}
          <ModelWrapper
            open={isOpen}
            onCancel={handleClose}
            title={`${isEdit ? "Edit" : "Add"} Flight Sim Game`}
            size={25}>
            <FormBg
              $radius="0.5"
              $padding="1.5"
              className="dark:border dark:border-gray-700 #374151 !max-w-full">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <InputField
                  className={"!rounded-lg"}
                  label="Game Name"
                  name="game_name"
                  type="text"
                  placeholder="Enter Game Name"
                  register={register}
                  error={errors?.game_name}
                />
                <CustomCheckbox
                  checkboxId={"game_name_active"}
                  label={"Active"}
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                  // color={"#868E96"}
                />
                <div className="flex justify-center">
                  <ButtonWrapper
                    className="focus:outline-none transition-all"
                    type="submit"
                    $color="#FFF"
                    $bg_color="#234DF0"
                    $size="1.125"
                    $radius="0.5">
                    {loading && <SpinLoader />}
                    {isEdit ? "Edit" : "Create"}
                  </ButtonWrapper>
                </div>
              </Form>
            </FormBg>
          </ModelWrapper>
        </div>

        {/* Table */}
        <div>
          <TableWrapper
            columns={columns}
            data={data}
            tableParams={tableParams}
            rowSelection={rowSelection}
            handleTableChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default FlightSimGame;
