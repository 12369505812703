import { FlightSimGameAPI, getHeaders } from "helper/API";
import { toastMessage } from "helper/Config";
import {
  LOADING_TRUE,
  LOADING_FALSE,
  GET_ALL_FLIGHT_SIM_GAME_REQUEST,
  GET_ALL_FLIGHT_SIM_GAME_SUCCESS,
  GET_ALL_FLIGHT_SIM_GAME_FAILURE,
  CREATE_FLIGHT_SIM_GAME,
  UPDATE_FLIGHT_SIM_GAME,
  DELETE_FLIGHT_SIM_GAME,
} from "../constants";
import { ErrorToast, SuccessToast } from "helper/Toast";

export const listAllFlightSimGame = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_FLIGHT_SIM_GAME_REQUEST });

      // API
      const response = await FlightSimGameAPI.get("/", { headers });

      const flightSimGameList = [];
      response?.data?.data?.forEach((x, index) => {
        flightSimGameList.push({
          index: index + 1,
          id: x?._id,
          game_name: x?.game_name,
          is_active: x?.is_active,
          createdAt: x?.createdAt,
          updatedAt: x?.updatedAt,
        });
      });

      dispatch({
        type: GET_ALL_FLIGHT_SIM_GAME_SUCCESS,
        payload: flightSimGameList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_FLIGHT_SIM_GAME_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const createFlightSimGame = (body, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getHeaders();

      // API
      const response = await FlightSimGameAPI.post(`/`, body, { headers });
      // console.log("🚀 ~ file: flightSimGameAction.js:158 ~ createFlightSimGame ~ response:", response);

      dispatch({
        type: CREATE_FLIGHT_SIM_GAME,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Flight sim game added successfully!");
        handleClose();
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      console.log("🚀 ~ file: flightSimGameAction.js:84 ~ createFlightSimGame ~ error:", error);

      dispatch({
        type: LOADING_FALSE,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const updateFlightSimGame = (body, flightSimGameId, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getHeaders();

      // API
      const response = await FlightSimGameAPI.put(`/${flightSimGameId}`, body, {
        headers,
      });
      // console.log("🚀 ~ file: flightSimGameAction.js:78 ~ response 😜👏", response);

      dispatch({
        type: UPDATE_FLIGHT_SIM_GAME,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Flight sim game updated successfully!");
        handleClose();
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      console.error("🚀 ~ file: flightSimGameAction.js:131 ~ error 😀👏:", error);

      dispatch({
        type: LOADING_FALSE,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const deleteFlightSimGame = (flightSimGameId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // console.log("🚀 ~ file: flightSimGameAction.js:158 ~ flightSimGameId:", flightSimGameId);

      // API
      const response = await FlightSimGameAPI.delete(`/${flightSimGameId}`, { headers });
      // console.log("🚀 ~ file: flightSimGameAction.js:161 ~ response 😜👏", response);

      dispatch({
        type: DELETE_FLIGHT_SIM_GAME,
        payload: flightSimGameId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Flight sim game deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      console.error("🚀 ~ file: flightSimGameAction.js:176 ~ error 😀👏:", error);

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};
