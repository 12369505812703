import React, { useState, useEffect } from "react";
import { TableWrapper, HeadingSearch, RenderCell, DateTimeFormat } from "components";
import styled from "styled-components";
import { LifeBuoy } from "lucide-react";
import { listAllReports, listSearchReports } from "redux_manager/actions";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { routesConfig } from "helper/Config";

const Report = () => {
  const { loading, reportList } = useSelector((state) => state.reports);
  // console.log("🚀 ~ file: Report.jsx:15 ~ Report ~ reportList:", reportList);
  const [query, setQuery] = useState(null);
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Report ID",
      dataIndex: "index",
      key: "index",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      width: 150,
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<DateTimeFormat value={value} />}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "report_type",
      key: "report_type",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      width: 100,
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value === "post_report" ? "Post" : value === "account_report" ? "User" : "Empty"}
        />
      ),
    },
    {
      title: "Reported User",
      dataIndex: "reported_user_account",
      key: "reported_user_account",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<div>{value?.firstname ? `${value?.firstname} ${value?.lastname || ""}` : `${value?.username}`}</div>}
        />
      ),
    },
    {
      title: "Reported Post",
      dataIndex: "reported_post_id",
      key: "reported_post_id",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value?._id ? value?._id : "Empty"}
        />
      ),
    },
    {
      title: "Reported By",
      dataIndex: "report_by",
      key: "report_by",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<div>{value?.firstname ? `${value?.firstname} ${value?.lastname || ""}` : `${value?.username}`}</div>}
        />
      ),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<div>{value}</div>}
        />
      ),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      align: "center",
      className: "text-xl",
      fixed: "right",
      width: 70,
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={
            <LinkWrapper to={`${routesConfig.reportDetail}/${value}`}>
              <EyeOutlined />
            </LinkWrapper>
          }
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleSearch = (value) => {
    // Here, you handle the search differently based on the presence of `value`
    if (value) {
      setQuery(value);
    } else {
      setQuery(null);
    }
  };

  const fetchReportApi = async () => {
    // API
    await dispatch(listAllReports());
  };

  const fetchSearchReportApi = async (query) => {
    // API
    await dispatch(listSearchReports(query));
  };

  useEffect(() => {
    fetchReportApi(); // Fetch initial data
  }, []);

  useEffect(() => {
    if (query !== null) {
      fetchSearchReportApi(query);
    } else {
      fetchReportApi();
    }
  }, [query]);

  useEffect(() => {
    const length = reportList?.length;
    setData(reportList);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: length,
      },
    });
  }, [reportList]);

  return (
    <div className="w-full p-16 text-white">
      <div className="flex flex-col gap-8">
        <div>
          <HeadingSearch
            Icon={LifeBuoy}
            title={"Report"}
            placeholder={"report"}
            onSearch={handleSearch}
          />
        </div>
        <div>
          <TableWrapper
            columns={columns}
            data={data}
            tableParams={tableParams}
            rowSelection={rowSelection}
            handleTableChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Report;

const LinkWrapper = styled(Link)`
  &:hover {
    color: limeGreen;
  }
`;
