import axios from "axios";
import { getHeaders } from "./API.header";
import { Auth } from "../Auth";
import { BASEURL } from "./API.base";

export const API = axios.create({
  headers: getHeaders().then((headers) => {
    return headers;
  }),
  baseURL: BASEURL,
});

API.interceptors.request.use(async (config) => {
  config.headers = await getHeaders();
  return config;
});

API.interceptors.response.use((response) => {
  if (response?.data?.status === 401) {
    Auth.adminLogout();
    window.location.reload();
  }
  return response;
});
