import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Pen } from "lucide-react";
import { Tooltip } from "antd";

const EditButton = ({ className }) => {
  const [arrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <Tooltip
      placement="top"
      title={"Edit"}
      arrow={mergedArrow}>
      <Edit
        size={20}
        role="button"
        className={`${className || ""}`}
      />
    </Tooltip>
  );
};

export default EditButton;

const Edit = styled(Pen)`
  ${"" /* cursor: pointer; */}
  &:hover {
    color: limeGreen;
  }
`;
