/* The code you provided is a JavaScript module that defines a userReducer function and exports it. The
userReducer function is a reducer function that handles different actions related to user data and
updates the state accordingly. */
// import * as constants from "redux/constants";
import {
  LOADING_TRUE,
  LOADING_FALSE,
  GET_ALL_HOME_AIRPORT_REQUEST,
  GET_ALL_HOME_AIRPORT_SUCCESS,
  GET_ALL_HOME_AIRPORT_FAILURE,
  CREATE_HOME_AIRPORT,
  UPDATE_HOME_AIRPORT,
  DELETE_HOME_AIRPORT,
} from "redux_manager/constants";

const initialState = {
  homeAirportList: [],
  loading: false,
  error: null,
};

export const homeAirportReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };

    case LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_HOME_AIRPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_HOME_AIRPORT_SUCCESS:
      return {
        ...state,
        homeAirportList: action?.payload,
        loading: false,
      };

    case GET_ALL_HOME_AIRPORT_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    case CREATE_HOME_AIRPORT:
      const { data: homeAirport } = action?.payload;
      const newHomeAirport = {
        id: homeAirport._id,
        name: homeAirport?.name,
        country: homeAirport?.country,
        is_active: homeAirport?.is_active,
        createdAt: homeAirport?.createdAt,
        updatedAt: homeAirport?.updatedAt,
      };

      return {
        ...state,
        homeAirportList: [...state.homeAirportList, newHomeAirport],
        loading: false,
      };

    case UPDATE_HOME_AIRPORT:
      const { data: UpdateHomeAirport } = action?.payload;

      return {
        ...state,
        homeAirportList: state.homeAirportList.map((x) => {
          if (x.id === UpdateHomeAirport._id) {
            return {
              index: x?.index,
              id: UpdateHomeAirport?._id,
              name: UpdateHomeAirport?.name,
              country: UpdateHomeAirport?.country,
              is_active: UpdateHomeAirport?.is_active,
              createdAt: UpdateHomeAirport?.createdAt,
              updatedAt: UpdateHomeAirport?.updatedAt,
            };
          }
          return x;
        }),
        loading: false,
      };

    case DELETE_HOME_AIRPORT:
      const homeAirportId = action?.payload;
      return {
        ...state,
        homeAirportList: state.homeAirportList.filter((x) => x.id !== homeAirportId),
      };

    default:
      return state;
  }
};
