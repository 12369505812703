import { Routes, Route, useLocation } from "react-router-dom";
import {
  Content,
  Dashboard,
  Feedbacks,
  FlightSimGame,
  HomeAirport,
  Homepage,
  Login,
  PageNotFound,
  Report,
  ReportDetails,
  UserDetails,
  Users,
} from "pages";
import { Side } from "layout";
import { routesConfig } from "helper/Config";
import * as reactHotToast from "react-hot-toast";
import { PrivateRouter, LoggedRouter } from "routers/securedRoutes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ScrollToTop } from "components";

const App = () => {
  const queryClient = new QueryClient();
  const { pathname } = useLocation();
  /* The `includesPath` constant is an array that contains the paths for the authentication routes in
  the application. These routes are used for login, creating an account, password recovery, OTP
  verification, and resetting the password. */
  const includesPath = [routesConfig?.login];

  return (
    <div className="flex bg-font_color_2">
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {!includesPath?.includes(pathname) ? <Side /> : null}
        <ScrollToTop>
          <Routes>
            <Route
              exact
              path={routesConfig.login}
              element={<LoggedRouter Component={Login} />}
            />
            <Route
              exact
              path={routesConfig.homePath}
              element={<PrivateRouter Component={Homepage} />}
            />
            <Route
              exact
              path={routesConfig.dashboard}
              element={<PrivateRouter Component={Dashboard} />}
            />
            <Route
              exact
              path={routesConfig.users}
              element={<PrivateRouter Component={Users} />}
            />
            <Route
              exact
              path={routesConfig.userDetails}
              element={<PrivateRouter Component={UserDetails} />}
            />
            <Route
              exact
              path={routesConfig.userDetailsId}
              element={<PrivateRouter Component={UserDetails} />}
            />
            <Route
              exact
              path={routesConfig.content}
              element={<PrivateRouter Component={Content} />}
            />
            <Route
              exact
              path={routesConfig.feedbacks}
              element={<PrivateRouter Component={Feedbacks} />}
            />
            <Route
              exact
              path={routesConfig.reports}
              element={<PrivateRouter Component={Report} />}
            />
            <Route
              exact
              path={routesConfig.reportDetailId}
              element={<PrivateRouter Component={ReportDetails} />}
            />
            <Route
              exact
              path={routesConfig.flightSimGame}
              element={<PrivateRouter Component={FlightSimGame} />}
            />
            <Route
              exact
              path={routesConfig.homeAirport}
              element={<PrivateRouter Component={HomeAirport} />}
            />
            <Route
              exact
              path={routesConfig.error}
              element={<PrivateRouter Component={PageNotFound} />}
            />
          </Routes>
        </ScrollToTop>
      </QueryClientProvider>
      <reactHotToast.Toaster />
    </div>
  );
};

export default App;
