/* The code you provided is a JavaScript module that defines a userReducer function and exports it. The
userReducer function is a reducer function that handles different actions related to user data and
updates the state accordingly. */
// import * as constants from "redux/constants";
import {
  GET_ALL_USER_REQUEST,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FAILURE,
  GET_SINGLE_USER_ACTIVITY_REQUEST,
  GET_SINGLE_USER_ACTIVITY_SUCCESS,
  GET_SINGLE_USER_ACTIVITY_FAILURE,
} from "redux_manager/constants";

const initialState = {
  usersList: [],
  usersActivityList: [],
  loading: false,
  userActivityLoading: false,
  error: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        usersList: action?.payload,
        loading: false,
      };

    case GET_ALL_USER_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    case GET_SINGLE_USER_ACTIVITY_REQUEST:
      return {
        ...state,
        userActivityLoading: true,
      };

    case GET_SINGLE_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        usersActivityList: action?.payload,
        userActivityLoading: false,
      };

    case GET_SINGLE_USER_ACTIVITY_FAILURE:
      return {
        userActivityLoading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
