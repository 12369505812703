/**
 * This JavaScript code exports several functions for making API calls to retrieve and manipulate
 * product data.
 * @returns The functions in this code are returning the response from the API calls made using the
 * `CommonApi` and `ReviewAPI` objects. If the API call is successful, the response data is returned.
 * If there is an error, the error object is returned.
 */
import axios from "axios";
import { ErrorToast } from "../Toast";
import { APIHeaderCors, getHeaders } from "./API.header";
import { BASEURL, AdminAuthAPI, AdminAPI } from "./API.base";
import { message } from "helper/Config";

const BasePath = "/";

// GET /api/ Common Api with no Authentication Header
export const ApiGetCommon = async (type, body) => {
  try {
    // API
    const response = await AdminAPI.get(type, body, APIHeaderCors);
    return response;
  } catch (error) {
    console.log("🚀 ~ file: APICall.js:24~ error 😀👏:", error);
    throw error;
  }
};

// POST /api/ Common Api with no Authentication Header
export const ApiPostCommon = async (type, body) => {
  try {
    // API
    const response = await AdminAPI.post(type, body, APIHeaderCors);

    return response;
  } catch (error) {
    console.log("🚀 ~ file: APICall.js:37~ error 😀👏:", error);
    throw error;
  }
};

/**
 * The function `makeAuthenticatedRequest` makes an authenticated HTTP request using the provided
 * method, type, id, and optional body.
 * @param method - The HTTP method to be used for the request (e.g., GET, POST, PUT, DELETE).
 * @param type - The `type` parameter in the `makeAuthenticatedRequest` function represents the type of
 * resource you want to make a request to. It could be a string value like "users", "posts", or any
 * other resource type in your API.
 * @param id - The `id` parameter is used to specify the identifier of the resource you want to make a
 * request to. It is typically used in the URL to identify a specific resource.
 * @param [body=null] - The `body` parameter is an optional parameter that represents the data to be
 * sent in the request body. It is used for methods like POST or PUT where you need to send data to the
 * server. If no `body` is provided, it defaults to `null`.
 * @returns The function `makeAuthenticatedRequest` returns a promise that resolves to the response
 * object from the axios request.
 */
export const makeAuthenticatedRequest = async (method, type, id, body = null) => {
  try {
    const headers = await getHeaders();

    // API URL
    const url = BASEURL + type + BasePath + id;

    // API
    const response = await axios({
      method,
      url,
      headers,
      body,
    });

    return response;
  } catch (error) {
    console.error("Error:", error);

    if (error?.message === "Network Error") ErrorToast(message?.NETWORK_ERROR);
    else if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else throw error;

    throw error;
  }
};

// API - Get with Authorization Header
export const ApiGetByIdAuthorization = async (type, id) => {
  return makeAuthenticatedRequest("get", type, id);
};

// API - Post with Authorization Header
export const ApiPostByIdAuthorization = async (type, id, body) => {
  return makeAuthenticatedRequest("post", type, id, body);
};

// API POST with Authentication Header
export const ApiPost = async (type, body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.post(BASEURL + type, body, {
      headers,
    });

    return response;
  } catch (error) {
    console.log("🚀 ~ file: ApiPost.js:102~ error 😀👏:", error);
    throw error;
  }
};

// API Get with Authentication Header
export const ApiGet = async (type) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.get(BASEURL + type, {
      headers,
    });

    return response;
  } catch (error) {
    console.log("🚀 ~ file: ApiPost.js:102~ error 😀👏:", error);
    throw error;
  }
};

// API Update || PUT
export const ApiPut = async (type, id, body) => {
  // return makeAuthenticatedRequest("put", type, id, body);

  try {
    const headers = await getHeaders();

    // API
    const response = await axios.put(BASEURL + type + BasePath + id, body, {
      headers,
    });

    return response;
  } catch (error) {
    console.log("🚀 ~ file: APICall.js:107 ~ ApiPut ~ error:", error);
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

// API Delete By Id
export const ApiDelete = async (type, id) => {
  return makeAuthenticatedRequest("delete", type, id);
};

// API Post - "application/json"
export const ApiPostJSON = async (type, body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.post(BASEURL + type, body, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("🚀 ~ file: API_Data.js:149 ~ ApiPostJSON ~ error:", error);
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};
