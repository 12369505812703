import Cookies from "js-cookie";
import { encrypt } from "helper/encrypt";

const COOKIE_OPTIONS = { sameSite: "strict", secure: true };

// Function to set a Encrypt cookie value
export const setEncryptCookie = (key, value, securityKey) => {
  try {
    let data = encrypt(value, securityKey);

    let expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 10 * 60 * 60 * 1000); // 10 hours in milliseconds

    Cookies.set(key, data, { expires: expirationDate, secure: true });
  } catch (error) {
    console.error(`Error setting data for key ${key}:`, error);
  }
};

// Function to set a cookie value
export const setCookie = (key, value, expires = 1) => {
  let expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 10 * 60 * 60 * 1000); // 10 hours in milliseconds

  Cookies.set(key, value, {
    expires: expirationDate,
    ...COOKIE_OPTIONS,
  });
};

// Function to get a cookie value
export const getCookie = (key) => Cookies.get(key);

// Function to remove a cookie
export const removeCookie = (key) => Cookies.remove(key, COOKIE_OPTIONS);
