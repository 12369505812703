import { ChevronFirst, ChevronLast } from "lucide-react";
// import profile from "../assets/profile.png";
import { createContext, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "assets/images";
import { Logout } from "components/ui/icon";
import { message, routesConfig } from "helper/Config";
import { Auth } from "helper/Auth";
import { SuccessToast } from "helper/Toast";

const SidebarContext = createContext();

export default function Sidebar({ children }) {
  const [expanded, setExpanded] = useState(true);

  const navigate = useNavigate();

  const handleLogout = () => {
    Auth?.adminLogout();
    navigate(routesConfig.login);
    SuccessToast(message.LOGGED_OUT_SUCCESS);
  };

  return (
    <>
      <aside className="h-screen bg-font_color_2 sticky top-0">
        <nav className="h-full flex flex-col bg-inherit border-primary_color_3 border-r shadow-sm text-white">
          <div className={`p-4 pb-2 flex ${expanded ? "justify-between" : "justify-center"} items-center`}>
            <img
              src={logo}
              alt="logo"
              className={`overflow-hidden transition-all ${expanded ? "w-32" : "w-0"}`}
            />
            <button
              onClick={() => setExpanded((curr) => !curr)}
              className={`p-1.5 rounded-lg ${
                expanded ? "bg-primary_color_3 hover:bg-primary_color_1" : "bg-primary_color_1 hover:bg-primary_color_3"
              }`}>
              {expanded ? <ChevronFirst className="bg-transparent" /> : <ChevronLast className="bg-transparent" />}
            </button>
          </div>

          <SidebarContext.Provider value={{ expanded }}>
            <ul className="bg-font_color_2 flex-1 px-4">{children}</ul>
          </SidebarContext.Provider>

          <button
            className={`border-t border-primary_color_3 flex px-4 py-3 items-center justify-start text-xl transition-all ${
              expanded ? "" : "!justify-center text-[0px]"
            }`}
            onClick={() => handleLogout()}>
            <Logout className={expanded ? "ml-4" : ""} />
            <div className={`flex justify-between items-center transition-all ${expanded ? "w-44 ml-5" : "w-0"} `}>
              <div className={`leading-4 text transition-all text-nowrap ${expanded ? "" : "overflow-hidden"}`}>
                <h4 className="font-semibold">Log Out</h4>
              </div>
            </div>
          </button>
        </nav>
      </aside>
    </>
  );
}

export function SidebarItem({ icon, text, active, alert, path }) {
  const { expanded } = useContext(SidebarContext);
  return (
    <li
      className={`bg-font_color_2 relative flex items-center py-2 px-3 my-5 text-xl rounded-md cursor-pointer transition-colors group ${
        active ? "!bg-primary_color_1" : "hover:!bg-primary_color_3"
      }`}>
      <Link
        to={path}
        className="flex bg-transparent">
        <span className={`flex items-center bg-transparent ${expanded ? "ml-4" : ""}`}>{icon}</span>
        <span className={`bg-transparent overflow-hidden transition-all ${expanded ? "w-38 ml-5" : "w-0 h-0"}`}>{text}</span>
        {alert && <div className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"}`}></div>}

        {!expanded && (
          <div
            className={`absolute left-full w-max rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
            {text}
          </div>
        )}
      </Link>
    </li>
  );
}
