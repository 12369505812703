import { TableWrapper, HeadingSearch, RenderCell, DateTimeFormat } from "components";
import React, { useState, useEffect } from "react";
import { MessageSquareQuote } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { listAllFeedbacks } from "redux_manager/actions";

const Feedbacks = () => {
  const { loading, feedbackList } = useSelector((state) => state.feedbacks);
  // console.log("🚀 ~ file: Feedbacks.jsx:12 ~ Feedbacks ~ feedbackList:", feedbackList);
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Feedback ID",
      dataIndex: "index",
      key: "index",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<DateTimeFormat value={value} />}
        />
      ),
    },
    {
      title: "By User",
      dataIndex: "user_id",
      key: "user_id",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<div>{value?.firstname ? `${value?.firstname} ${value?.lastname || ""}` : `${value?.username}`}</div>}
        />
      ),
    },
    {
      title: "Title",
      dataIndex: "feedback_title",
      key: "feedback_title",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    const fetchFeedbackApi = async () => {
      // API
      await dispatch(listAllFeedbacks());
      const length = feedbackList.length;
      setData(feedbackList);

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: length,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
    };
    fetchFeedbackApi();
  }, [dispatch, JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const onSearch = (value) => {
    console.log("value: " + value);
  };

  return (
    <div className="w-full p-16 text-white">
      <div className="flex flex-col gap-8">
        <div>
          <HeadingSearch
            Icon={MessageSquareQuote}
            title={"Feedback"}
            placeholder={"feedback"}
            onSearch={onSearch}
          />
        </div>
        <div>
          <TableWrapper
            columns={columns}
            data={data}
            tableParams={tableParams}
            rowSelection={rowSelection}
            handleTableChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Feedbacks;
