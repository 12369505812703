import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TableWrapper, Heading, RenderCell, Loader, DateTimeFormat } from "components";
import { ArrowLeft } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { no_profile } from "assets/images";
import { routesConfig } from "helper/Config";
import { API, ApiGetByIdAuthorization, getHeaders } from "helper/API";
import { listAllUserActivity } from "redux_manager/actions/userAction";
import { useDispatch, useSelector } from "react-redux";

const UserDetails = () => {
  const { userActivityLoading, usersActivityList } = useSelector((state) => state.users);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: userId } = useParams();

  const columns = [
    {
      title: "Content ID",
      dataIndex: "index",
      key: "index",
      align: "center",
      width: 150,
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={userActivityLoading}
          content={value}
        />
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={userActivityLoading}
          content={<DateTimeFormat value={value} />}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: ["images", "videos"],
      key: ["images", "videos"],
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={userActivityLoading}
          content={!!record["images"]?.length ? "Image" : !!record["videos"]?.length ? "Video" : "Empty"}
        />
      ),
    },
    {
      title: "Views",
      dataIndex: "total_views_count",
      key: "total_views_count",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={userActivityLoading}
          content={value}
        />
      ),
    },
    {
      title: "Likes",
      dataIndex: "likes_count",
      key: "likes_count",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={userActivityLoading}
          content={value}
        />
      ),
    },
    {
      title: "Likes",
      dataIndex: "comment_counts",
      key: "comment_counts",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={userActivityLoading}
          content={value}
        />
      ),
    },
    {
      title: "Reports",
      dataIndex: "reports_counts",
      key: "reports_counts",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={userActivityLoading}
          content={value}
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const fetchUserDetails = async () => {
    setIsLoading(true);
    try {
      const headers = await getHeaders();

      const response = await API.get(`/users/${userId}`, {
        headers
      })

      setUserDetails(response?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("🚀 ~ file: UserDetails.jsx:160 ~ fetchUserDetails ~ error:", error);
    }
  };

  const fetchUserActivityApi = async () => {
    // API
    await dispatch(listAllUserActivity(userId));
    const length = usersActivityList.length;
    setData(usersActivityList);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: length,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
  };

  useEffect(() => {
    // API
    fetchUserDetails();
  }, [userId]);

  useEffect(() => {
    // API
    fetchUserActivityApi();
  }, [dispatch, JSON.stringify(tableParams)]);

  return (
    <div className="w-full p-16 text-white">
      <div className="mb-4">
        <button onClick={() => navigate(routesConfig.users)}>
          <ArrowLeft size={40} />
        </button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-10">
          {/* User Account */}
          <div>
            <div className="w-full flex items-center justify-between gap-4 mb-4">
              <Heading title={"User Account"} />
              {/* <div className="flex items-center gap-12 mr-20">
                <Button
                  $color={"#E6E6E6"}
                  $bgColor={"#363636"}
                  className="text-sm text-font_color_2 px-4 py-[2px] bg-bg_color_1 rounded-xl text-center font-semibold leading-tight transition-colors">
                  <p>Temporarily</p>
                  <p>Suspend</p>
                </Button>
                <Button
                  $color={"#E6E6E6"}
                  $bgColor={"#363636"}
                  className="text-sm text-font_color_2 px-4 py-[2px] bg-bg_color_1 rounded-xl text-center font-semibold leading-tight transition-colors">
                  <p>Permanently</p>
                  <p>Suspend</p>
                </Button>
              </div> */}
            </div>
            <div className="flex items-center px-3 py-8 gap-16 rounded-[40px] bg-bg_color_2">
              <div className="px-3">
                <div>
                  <img
                    className="h-[150px] w-[150px] mx-auto rounded-full"
                    src={userDetails?.profile_picture || no_profile}
                    alt="user_image"
                  />
                </div>
                <div>
                  <h4 className="text-2xl font-bold text-center my-1">{`${userDetails?.firstname || ""}
                ${userDetails?.lastname || ""}`}</h4>
                  <p className="text-xl text-center mb-1">{userDetails?.username}</p>
                  <p className="flex items-center justify-center text-center text-lg mb-1">
                    <span className="text-nowrap">Member since :</span>{" "}
                    <span className="ml-2">
                      <DateTimeFormat
                        value={userDetails?.created_at}
                        format={"D/MM/YY"}
                      />
                    </span>
                    <span className="ml-2">
                      <DateTimeFormat
                        value={userDetails?.created_at}
                        format={"HH:mm"}
                      />
                    </span>
                  </p>
                  {/* <p className="flex items-center justify-center text-center text-lg">
                  <span>Last Activity:</span> <span className="ml-1">15/01/23</span> <span className="ml-2">00:50</span>
                </p> */}
                </div>
              </div>
              <div className=" flex flex-col gap-8">
                <div>
                  <h1 className="text-3xl font-semibold mb-8">Personal Information</h1>
                  <div className="flex flex-wrap items-center gap-x-12 gap-y-6 text-2xl">
                    <div className="flex items-center">
                      <span className="">Email :</span>
                      <p className="ml-2 text-font_color_3">{userDetails?.email}</p>
                    </div>
                    {/* <div className="flex items-center">
                    <span className="">Phone:</span>
                    <p className="ml-2 text-font_color_3">(01)-000-000-0000</p>
                  </div> */}
                    {/* <div className="flex items-center">
                    <span className="">Gender:</span>
                    <p className="ml-2 text-font_color_1">Male</p>
                  </div> */}
                    {/* <div className="flex items-center">
                    <span className="">DOB:</span>
                    <p className="ml-2 text-font_color_1">05/01/1999</p>
                  </div> */}
                  </div>
                </div>
                <div>
                  <h1 className="text-3xl font-semibold mb-8">Profile Information</h1>
                  <div className="flex flex-wrap items-center gap-x-12 gap-y-6 text-2xl">
                    <div className="flex items-center">
                      <span className="">Username :</span>
                      <p className="ml-2 text-font_color_3">{userDetails?.username || ""}</p>
                    </div>
                    <div className="flex items-center">
                      <span className="">Posts :</span>
                      <p className="ml-2 text-font_color_1">{userDetails?.totalPosts || 0}</p>
                    </div>
                    <div className="flex items-center">
                      <span className="">Flight simulator :</span>
                      <p className="ml-2 text-font_color_1">{userDetails?.flight_sim_game_id?.game_name || "No record"}</p>
                    </div>
                    <div className="flex items-center">
                      <span className="">PC space :</span>
                      {userDetails?.gpu && userDetails?.memory && userDetails?.processor && userDetails?.graphics_card ? (
                        <p className="ml-2 text-font_color_1">{`${userDetails?.gpu || ""} ${userDetails?.memory || ""} ${
                          userDetails?.processor || ""
                        } ${userDetails?.graphics_card || ""}`}</p>
                      ) : (
                        <p className="ml-2 text-font_color_1">{"No spec provided"}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Activity */}
          <div>
            <Heading title={"Activity"} />
          </div>
          <div>
            <TableWrapper
              columns={columns}
              data={data}
              tableParams={tableParams}
              rowSelection={rowSelection}
              handleTableChange={handleTableChange}
              loading={userActivityLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;

const Button = styled.button`
  &:hover {
    background-color: ${({ $bgColor }) => $bgColor};

    p {
      color: ${({ $color }) => $color};
    }
  }
`;
