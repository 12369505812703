import { AdminAuthAPI, APIHeaderCors } from "helper/API";
import { Auth } from "helper/Auth";
import { message, routesConfig } from "helper/Config";
import * as constants from "redux_manager/constants";
import { ErrorToast, SuccessToast } from "helper/Toast";

export const loginAdminAction = (adminData, navigate, reset) => async (dispatch) => {
  try {
    dispatch({ type: constants.ADMIN_REQUEST });

    // AXIOS BODY
    const body = {
      email: adminData?.email,
      password: adminData?.password,
    };

    // API
    const response = await AdminAuthAPI.post("/admin-signin", body, APIHeaderCors);
    // console.log("🚀 ~ file: adminAction.js:41 ~ response:", response);

    dispatch({
      type: constants.ADMIN_SUCCESS,
      payload: response?.data?.user,
    });

    if (response?.data?.token) {
      Auth?.setToken(response?.data?.token);
      Auth?.setAdminEmail(adminData?.email);
      navigate(routesConfig?.dashboard);
      SuccessToast(message.LOGIN_SUCCESS);
    } else if (response?.data?.status === 401) ErrorToast(message?.LOGIN_ADMIN_INVALID_EMAIL_OR_PASSWORD);
    else if (response?.data?.status === 403) ErrorToast(message?.LOGIN_ADMIN_INVALID_EMAIL_OR_PASSWORD);
    else return;

    reset();
    return response;
  } catch (error) {
    reset();
    console.error("🚀 ~ file: adminAction.js:60 ~ error 😀👏:", error);

    dispatch({
      type: constants.ADMIN_FAILURE,
      payload: error?.message,
    });

    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(message?.NETWORK_ERROR);
    else return;

    throw error;
  }
};

export const resetAdminData = () => async (dispatch) => {
  try {
    dispatch({ type: constants.ADMIN_RESET });
  } catch (error) {
    console.log("🚀 ~ file: adminAction.js:124 ~ resetAdminData ~ error:", error);
  }
};
