import { routesConfig } from "helper/Config";
import React from "react";
import { Link } from "react-router-dom";

const UserCard = ({ id, profileImage, name, flightSimName, username, posts }) => {
  return (
    <Link
      to={`${routesConfig.userDetails}/${id}`}
      className="text-font_color_1">
      <div className="">
        <img
          className="h-[120px] w-[120px] mx-auto rounded-full"
          src={profileImage}
          alt="user_image"
        />
      </div>
      <div>
        <h4 className="text-2xl text-center mt-2 mb-1">{name}</h4>
        <p className="text-2xl font-semibold text-center mb-1">{username}</p>
        <p className="text-wrap text-xl text-center mb-1">{flightSimName}</p>
        <p className="text-center mb-1">Posts</p>
        <p className="text-center font-light">{posts}</p>
      </div>
    </Link>
  );
};

export default UserCard;
