import React from "react";
import { Skeleton } from "antd";

const RenderCell = ({ loading, content }) => {
  return loading ? (
    <Skeleton
      active
      size
      title={{ width: "inherit" }}
      paragraph={false}
    />
  ) : (
    content
  );
};

export default RenderCell;
