/* The code you provided is a JavaScript module that defines a userReducer function and exports it. The
userReducer function is a reducer function that handles different actions related to user data and
updates the state accordingly. */
// import * as constants from "redux/constants";
import {
  LOADING_TRUE,
  LOADING_FALSE,
  GET_ALL_FLIGHT_SIM_GAME_REQUEST,
  GET_ALL_FLIGHT_SIM_GAME_SUCCESS,
  GET_ALL_FLIGHT_SIM_GAME_FAILURE,
  CREATE_FLIGHT_SIM_GAME,
  UPDATE_FLIGHT_SIM_GAME,
  DELETE_FLIGHT_SIM_GAME,
} from "redux_manager/constants";

const initialState = {
  flightSimGameList: [],
  loading: false,
  error: null,
};

export const flightSimGameReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };

    case LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_FLIGHT_SIM_GAME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_FLIGHT_SIM_GAME_SUCCESS:
      return {
        ...state,
        flightSimGameList: action?.payload,
        loading: false,
      };

    case GET_ALL_FLIGHT_SIM_GAME_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    case CREATE_FLIGHT_SIM_GAME:
      const { data: flightSimGame } = action?.payload;
      const newFlightSimGame = {
        id: flightSimGame._id,
        game_name: flightSimGame?.game_name,
        is_active: flightSimGame?.is_active,
        createdAt: flightSimGame?.createdAt,
        updatedAt: flightSimGame?.updatedAt,
      };

      return {
        ...state,
        flightSimGameList: [...state.flightSimGameList, newFlightSimGame],
        loading: false,
      };

    case UPDATE_FLIGHT_SIM_GAME:
      const { data: UpdateFlightSimGame } = action?.payload;

      return {
        ...state,
        flightSimGameList: state.flightSimGameList.map((x) => {
          if (x.id === UpdateFlightSimGame._id) {
            return {
              index: x.index,
              id: UpdateFlightSimGame._id,
              game_name: UpdateFlightSimGame?.game_name,
              is_active: UpdateFlightSimGame?.is_active,
              createdAt: UpdateFlightSimGame?.createdAt,
              updatedAt: UpdateFlightSimGame?.updatedAt,
            };
          }
          return x;
        }),
        loading: false,
      };

    case DELETE_FLIGHT_SIM_GAME:
      const flightSimGameId = action?.payload;
      return {
        ...state,
        flightSimGameList: state.flightSimGameList.filter((x) => x.id !== flightSimGameId),
      };

    default:
      return state;
  }
};
