import { ContentAPI, getHeaders } from "helper/API";
import { toastMessage } from "helper/Config";
import { GET_ALL_CONTENT_REQUEST, GET_ALL_CONTENT_SUCCESS, GET_ALL_CONTENT_FAILURE } from "../constants";
import { ErrorToast } from "helper/Toast";

export const listAllContent = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_CONTENT_REQUEST });

      // API
      const response = await ContentAPI.get("/admin", { headers });

      const contentList = [];
      response?.data?.forEach((x, index) => {
        contentList.push({
          ...x,
          id: x?._id,
          index: index + 1,
        });
      });

      dispatch({
        type: GET_ALL_CONTENT_SUCCESS,
        payload: contentList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_CONTENT_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const listSearchContent = (query) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {};
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_CONTENT_REQUEST });

      // API
      const response = await ContentAPI.post(`/search-feed?searchQuery=${query}`, body, { headers });

      const contentList = [];
      response?.data?.posts?.forEach((x) => {
        contentList.push({
          ...x,
          id: x?._id,
        });
      });

      dispatch({
        type: GET_ALL_CONTENT_SUCCESS,
        payload: contentList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_CONTENT_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};
