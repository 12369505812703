/* The code block you provided is defining a set of constants for feedback-related actions in a JavaScript
application. Each constant represents a specific action that can be dispatched in the application. */

// FEEDBACK CONSTANTS //

//------------------------------------------------------------------//
// GET ALL USER
export const GET_ALL_FEEDBACK_REQUEST = "GET_ALL_FEEDBACK_REQUEST";
export const GET_ALL_FEEDBACK_SUCCESS = "GET_ALL_FEEDBACK_SUCCESS";
export const GET_ALL_FEEDBACK_FAILURE = "GET_ALL_FEEDBACK_FAILURE";
//------------------------------------------------------------------//
