import React from "react";
import { API, BASEURL } from "helper/API";
import { useQuery } from "@tanstack/react-query";
import CanvasJSReact from "@canvasjs/react-charts";
import { Space } from "antd";
import { Users, Clapperboard, MessageSquareQuote, LifeBuoy } from "lucide-react";
import styled from "styled-components";
import { Heading, Overview } from "components";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Dashboard = () => {
  const fetchDashboardCount = () => API.get(`${BASEURL}/dashboard/count`).then((response) => response.data);

  const fetchChartAPI = () => API.post(`${BASEURL}/dashboard/chart-data`).then((response) => response.data);

  const {
    // isLoading: countsLoading,
    data: countData,
    // error: countError,
  } = useQuery({ queryKey: ["counts"], queryFn: fetchDashboardCount, staleTime: 30000 });

  const {
    // isLoading: chartLoading,
    data: chartData,
    // error: chartError,
  } = useQuery({ queryKey: ["chart"], queryFn: fetchChartAPI, staleTime: 30000 });

  // const handleChange = (value) => {
  //   console.log("🚀 ~ file: Dashboard.jsx:20 ~ handleChange ~ value:", value);
  // };

  // Table options
  const options = {
    backgroundColor: "transparent",
    animationEnabled: true,
    // title: {
    //   text: "Monthly Sales - 2017",
    // },

    theme: "dark1", // "light1", "light2", "dark1", "dark2"
    axisX: {
      lineThickness: 0,
      tickThickness: 0,
      valueFormatString: "MMM",
      intervalType: "month",
      interval: 1,
      labelFontSize: 18,
      labelFontWeight: "normal",
    },
    axisY: {
      lineThickness: 0,
      gridThickness: 0,
      tickLength: 0,
      // title: "Sales (in USD)",
      // prefix: "$",
      includeZero: false,
      labelFontSize: 18,
      labelFontWeight: "normal",
    },
    // height: 400,
    data: [
      {
        // yValueFormatString: "###",
        // xValueFormatString: "MMMM",
        type: "spline",
        lineColor: "#0090DF",
        indexLabelFontSize: 16,
        markerSize: 10,
        dataPoints: [
          // y: Math.floor(Math.random() * 99) + 1
          { x: new Date(2024, 0), y: chartData?.[0]?.count, color: "#E6E6E6", toolTipContent: "January" },
          { x: new Date(2024, 1), y: chartData?.[1]?.count, color: "#E6E6E6", toolTipContent: "February" },
          { x: new Date(2024, 2), y: chartData?.[2]?.count, color: "#E6E6E6", toolTipContent: "March" },
          { x: new Date(2024, 3), y: chartData?.[3]?.count, color: "#E6E6E6", toolTipContent: "April" },
          { x: new Date(2024, 4), y: chartData?.[4]?.count, color: "#E6E6E6", toolTipContent: "May" },
          { x: new Date(2024, 5), y: chartData?.[5]?.count, color: "#E6E6E6", toolTipContent: "June" },
          { x: new Date(2024, 6), y: chartData?.[6]?.count, color: "#E6E6E6", toolTipContent: "July" },
          { x: new Date(2024, 7), y: chartData?.[7]?.count, color: "#E6E6E6", toolTipContent: "August" },
          { x: new Date(2024, 8), y: chartData?.[8]?.count, color: "#E6E6E6", toolTipContent: "September" },
          { x: new Date(2024, 9), y: chartData?.[9]?.count, color: "#E6E6E6", toolTipContent: "October" },
          { x: new Date(2024, 10), y: chartData?.[10]?.count, color: "#E6E6E6", toolTipContent: "November" },
          { x: new Date(2024, 11), y: chartData?.[11]?.count, color: "#E6E6E6", toolTipContent: "December" },
        ].map((point, index, points) => {
          const previousY = index > 0 ? points[index - 1].y : 0;
          const diff = point.y - previousY;
          const percentageChange =
            previousY !== 0 ? ((diff / previousY) * 100).toFixed(2) : "";
          const changeSymbol = diff > 0 ? "+" : "";
          const includeLabel = point.y !== 0;

          return {
            ...point,
            toolTipContent: includeLabel
              ? `${point.toolTipContent}: {y}`
              : null,
            indexLabel:
              includeLabel && percentageChange
                ? `${changeSymbol}${percentageChange}%`
                : null,
          };
        }),
      },
    ],
  };

  return (
    <div className="w-full p-16 text-font_color_1">
      <div className="flex flex-col gap-16">
        <div className="flex justify-between gap-2">
          <Heading title={"Overview"} />
          {/* <SelectWrapper
            wrap
            className="w-36">
            <Select
              className="w-36 text-medium"
              defaultValue="last_one_year"
              // style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: "last_one_year", label: "Last one year" },
                { value: "lucy", label: "Lucy" },
                { value: "hello", label: "Hello" },
              ]}
            />
          </SelectWrapper> */}
        </div>
        <Grid className=" text-font_color_2">
          <Overview
            Icon={Users}
            title={"Users"}
            count={countData?.totalUserCount}
            // percent={"46 %"}
            subTitle={"Users"}
            bgColor={"#CAFDCD"}
          />
          <Overview
            Icon={Clapperboard}
            title={"Content"}
            count={countData?.totalPostCount}
            // percent={"25 %"}
            subTitle={"Posts"}
            bgColor={"#C8EDFA"}
          />
          <Overview
            Icon={MessageSquareQuote}
            title={"Feedback"}
            count={countData?.totalUserFeedbackCount}
            // percent={"46 %"}
            subTitle={"Feedbacks"}
            bgColor={"#C7D1FA"}
          />
          <Overview
            Icon={LifeBuoy}
            title={"Report"}
            count={countData?.totalReportCount}
            // percent={"46 %"}
            subTitle={"Cases"}
            bgColor={"#D9AEAE"}
          />
        </Grid>
        <div>
          <Heading title={"Post (Per Month)"} />
        </div>
        <div className="">
          <CanvasJSChart
            options={options}
            /* onRef={ref => this.chart = ref} */
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

const Grid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

const SelectWrapper = styled(Space)`
  .ant-select-selector,
  :where(.css-dev-only-do-not-override-1kuana8).ant-select-dropdown
    .ant-select-item {
    font-size: 1rem;
    border-radius: 20px;
  }
`;
