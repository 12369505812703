import React, { useEffect, useState } from "react";
import { Users as UserIcon } from "lucide-react";
import { HeadingSearch, Loader, UserCard } from "components";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { listAllUsers, listSearchUsers } from "redux_manager/actions";
import { no_profile } from "assets/images";

const Users = () => {
  const { loading, usersList } = useSelector((state) => state.users);
  // console.log("🚀 ~ file: Users.jsx:11 ~ Users ~ usersList:", usersList);
  const [query, setQuery] = useState(null);

  const dispatch = useDispatch();

  const handleSearch = (value) => {
    // Here, you handle the search differently based on the presence of `value`
    if (value) {
      setQuery(value);
    } else {
      setQuery(null);
    }
  };

  useEffect(() => {
    if (query) {
      dispatch(listSearchUsers(query));
    } else {
      dispatch(listAllUsers());
    }
  }, [dispatch, query]);

  return (
    <div className="w-full p-16 text-white">
      <div className="flex flex-col gap-16">
        <HeadingSearch
          Icon={UserIcon}
          fill={"#FFFFFF"}
          title={"Users"}
          placeholder={"users"}
          onSearch={handleSearch}
        />
        <div>
          <Grid className="">
            {loading ? (
              <Loader />
            ) : !!usersList.length ? (
              usersList?.map((x, index) => {
                return (
                  <UserCard
                    key={index}
                    id={x?.id}
                    profileImage={x?.profile_picture || no_profile}
                    name={`${x?.firstname} ${x?.lastname || ""}`}
                    flightSimName={x?.flight_sim_game_id?.game_name || "No flight sim"}
                    username={x?.username}
                    posts={x?.totalPosts || 0}
                  />
                );
              })
            ) : (
              <p className="text-xl text-center">No user</p>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Users;

const Grid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  /* Link tag start */
  a {
    background: linear-gradient(to bottom, #303030, #505050);
    border-radius: 2rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  /* Link tag end */
`;
