/* This code is determining the API configuration based on the current hostname and port of the
website. */
const hostName = window.location.hostname;
const port = window.location.port;
let isLocalApi = port >= 3000;

const LIVE_API = process.env.REACT_APP_SERVER_API_BASEURL;

const LOCAL_API = process.env.REACT_APP_SERVER_API_BASEURL;

export const API_CONFIG = hostName === "localhost" && isLocalApi ? LOCAL_API : LIVE_API;
