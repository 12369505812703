import { Auth } from "../Auth";

/* The `commonHeaders` constant is defining a set of headers that are commonly used for making API
requests. These headers are related to Cross-Origin Resource Sharing (CORS), which is a mechanism
that allows resources (e.g., fonts, JavaScript, etc.) on a web page to be requested from another
domain outside the domain from which the resource originated. */
const commonHeaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
};

/**
 * The function `getHeaders` returns a set of headers including a token for authorization.
 * @returns The function `getHeaders` returns an object with the following properties:
 */
export const getHeaders = async () => {
  let token = await Auth?.getToken();

  return {
    ...commonHeaders,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const getHeadersCors = async () => {
  return {
    ...commonHeaders,
    "Content-Type": "application/json",
  };
};

/* The `getFormDataHeaders` function is returning a set of headers that can be used for
making API requests with `multipart/form-data` content type. It includes the
`commonHeaders` and adds the `Content-Type` header with the value
`multipart/form-data`. It also includes an authorization token in the `Authorization`
header if available. */
export const getFormDataHeaders = async () => {
  let token = await Auth?.getToken();

  return {
    ...commonHeaders,
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
};

/* The `APIHeaderCors` object is defining a set of headers that can be used for making API requests
with Cross-Origin Resource Sharing (CORS) enabled. */
export const APIHeaderCors = {
  headers: {
    ...commonHeaders,
    "Content-Type": "application/json",
  },
};
