/* The code block you provided is defining a set of constants for admin-related actions in a JavaScript
application. Each constant represents a specific action that can be dispatched in the application. */

//------------------------------------------------------------------//
export const ADMIN_REQUEST = "ADMIN_REQUEST";
export const ADMIN_SUCCESS = "ADMIN_SUCCESS";
export const ADMIN_FAILURE = "ADMIN_FAILURE";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const ADMIN_RESET = "ADMIN_RESET";

// ADMIN CONSTANTS //

//------------------------------------------------------------------//
// LOGIN ADMIN
export const LOGIN_ADMIN_REQUEST = "LOGIN_ADMIN_REQUEST";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILURE = "LOGIN_ADMIN_FAILURE";
//------------------------------------------------------------------//
