import React, { useEffect, useState } from "react";
import { TableWrapper, HeadingSearch, RenderCell, DateTimeFormat } from "components";
import { Clapperboard } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { listAllContent, listSearchContent } from "redux_manager/actions";

const Content = () => {
  const [query, setQuery] = useState(null);
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const { loading, contentList } = useSelector((state) => state.content);
  // console.log("🚀 ~ file: Content.jsx:20 ~ Content ~ contentList:", contentList);
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Content ID",
      dataIndex: "index",
      key: "index",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      width: 150,
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<DateTimeFormat value={value} />}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: ["images", "videos"],
      key: ["images", "videos"],
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={!!record["images"]?.length ? "Image" : !!record["videos"]?.length ? "Video" : "Empty"}
        />
      ),
    },
    {
      title: "Views",
      dataIndex: "total_views_count",
      key: "total_views_count",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Likes",
      dataIndex: "likes_count",
      key: "likes_count",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Comments",
      dataIndex: "comment_counts",
      key: "comment_counts",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Reports",
      dataIndex: "reports_counts",
      key: "reports_counts",
      align: "center",
      className: "text-xl",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleSearch = (value) => {
    // Here, you handle the search differently based on the presence of `value`
    if (value) {
      setQuery(value);
    } else {
      setQuery(null);
    }
  };

  const fetchContentApi = async () => {
    // API
    await dispatch(listAllContent());
  };

  const fetchSearchContentApi = async (query) => {
    // API
    await dispatch(listSearchContent(query));
  };

  useEffect(() => {
    fetchContentApi(); // Fetch initial data
  }, []);

  useEffect(() => {
    if (query !== null) {
      fetchSearchContentApi(query);
    } else {
      fetchContentApi();
    }
  }, [query]);

  useEffect(() => {
    const length = contentList?.length;
    setData(contentList);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: length,
      },
    });
  }, [contentList]);

  return (
    <div className="w-full p-16 text-white">
      <div className="flex flex-col gap-8">
        <div>
          <HeadingSearch
            Icon={Clapperboard}
            title={"Content"}
            placeholder={"contents"}
            onSearch={handleSearch}
          />
        </div>
        <div>
          <TableWrapper
            columns={columns}
            data={data}
            tableParams={tableParams}
            rowSelection={rowSelection}
            handleTableChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Content;
