import React from "react";
import { EmailIcon, InputField, Logo, PasswordIcon } from "components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonWrapper, Form, Head2 } from "assets/styles";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAdminAction } from "redux_manager/actions";
import { loginSchema } from "helper";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (values) => {
    try {
      dispatch(loginAdminAction(values, navigate, reset));
      // Submit the form data here
    } catch (error) {
      console.log("🚀 ~ file: Login.jsx:28 ~ onSubmit ~ error:", error);
    }
  };

  return (
    <div className="w-full h-screen !bg-font_color_2 text-font_color_1 flex items-center justify-center gap-4 p-4">
      <div className="flex items-center justify-center h-full w-full">
        <FormBg className="dark:border dark:border-gray-700 #374151">
          <div className="flex flex-col gap-4 items-center text-center">
            <div>
              <Logo fill={"#FFF"} />
            </div>
            <Head2
              className="mb-[0.37rem]"
              $color={"#FFF"}
              $size={"1.7rem"}>
              Sign in to admin
            </Head2>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              label="Email"
              name="email"
              type="email"
              placeholder="Email"
              register={register}
              error={errors?.email}
              fieldIcon={<EmailIcon />}
            />
            <InputField
              label="Password"
              name="password"
              type="password"
              placeholder="Password"
              register={register}
              error={errors?.password}
              fieldIcon={<PasswordIcon />}
            />
            <ButtonWrapper
              className="focus:outline-none"
              type="submit"
              $color="#FFF"
              $bg_color="#234DF0"
              $width="100%">
              Login
            </ButtonWrapper>
          </Form>
        </FormBg>
      </div>
    </div>
  );
};

export default Login;

const FormBg = styled.div`
  border-radius: 1.5rem;
  background: #1f2937;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 28rem;
`;
