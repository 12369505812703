import { HomeAirportAPI, getHeaders } from "helper/API";
import { toastMessage } from "helper/Config";
import {
  LOADING_TRUE,
  LOADING_FALSE,
  GET_ALL_HOME_AIRPORT_REQUEST,
  GET_ALL_HOME_AIRPORT_SUCCESS,
  GET_ALL_HOME_AIRPORT_FAILURE,
  CREATE_HOME_AIRPORT,
  UPDATE_HOME_AIRPORT,
  DELETE_HOME_AIRPORT,
} from "../constants";
import { ErrorToast, SuccessToast } from "helper/Toast";

export const listAllHomeAirport = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_HOME_AIRPORT_REQUEST });

      // API
      const response = await HomeAirportAPI.get("/", { headers });

      const homeAirportList = [];
      response?.data?.data?.forEach((x, index) => {
        homeAirportList.push({
          index: index + 1,
          id: x?._id,
          name: x?.name,
          country: x?.country,
          is_active: x?.is_active,
          createdAt: x?.createdAt,
          updatedAt: x?.updatedAt,
        });
      });

      dispatch({
        type: GET_ALL_HOME_AIRPORT_SUCCESS,
        payload: homeAirportList,
      });

      resolve(response);
    } catch (error) {
      console.log("🚀 ~ file: homeAirportAction.js:45 ~ error:", error);
      dispatch({
        type: GET_ALL_HOME_AIRPORT_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const createHomeAirport = (body, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getHeaders();

      // API
      const response = await HomeAirportAPI.post(`/`, body, { headers });
      // console.log("🚀 ~ file: homeAirportAction.js:158 ~ createHomeAirport ~ response:", response);

      dispatch({
        type: CREATE_HOME_AIRPORT,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Favorite Airport added successfully!");
        handleClose();
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      console.log("🚀 ~ file: homeAirportAction.js:84 ~ createHomeAirport ~ error:", error);

      dispatch({
        type: LOADING_FALSE,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const updateHomeAirport = (body, flightSimGameId, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getHeaders();

      // API
      const response = await HomeAirportAPI.put(`/${flightSimGameId}`, body, {
        headers,
      });
      // console.log("🚀 ~ file: homeAirportAction.js:78 ~ response 😜👏", response);

      dispatch({
        type: UPDATE_HOME_AIRPORT,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Favorite Airport updated successfully!");
        handleClose();
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      console.error("🚀 ~ file: homeAirportAction.js:131 ~ error 😀👏:", error);

      dispatch({
        type: LOADING_FALSE,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const deleteHomeAirport = (flightSimGameId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // console.log("🚀 ~ file: homeAirportAction.js:158 ~ flightSimGameId:", flightSimGameId);

      // API
      const response = await HomeAirportAPI.delete(`/${flightSimGameId}`, { headers });
      // console.log("🚀 ~ file: homeAirportAction.js:161 ~ response 😜👏", response);

      dispatch({
        type: DELETE_HOME_AIRPORT,
        payload: flightSimGameId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Favorite Airport deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      console.error("🚀 ~ file: homeAirportAction.js:176 ~ error 😀👏:", error);

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};
