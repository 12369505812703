import { FeedbackAPI, getHeaders } from "helper/API";
import { toastMessage } from "helper/Config";
import { GET_ALL_FEEDBACK_REQUEST, GET_ALL_FEEDBACK_SUCCESS, GET_ALL_FEEDBACK_FAILURE } from "../constants";
import { ErrorToast } from "helper/Toast";

export const listAllFeedbacks = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_FEEDBACK_REQUEST });

      // API
      const response = await FeedbackAPI.get("/", { headers });

      const feedbackList = [];
      response?.data?.forEach((x, index) => {
        feedbackList.push({
          ...x,
          id: x?._id,
          index: index + 1,
        });
      });

      dispatch({
        type: GET_ALL_FEEDBACK_SUCCESS,
        payload: feedbackList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_FEEDBACK_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};
