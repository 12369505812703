/* The code block you provided is defining a set of constants for content-related actions in a JavaScript
application. Each constant represents a specific action that can be dispatched in the application. */

// CONTENT CONSTANTS //

//------------------------------------------------------------------//
// GET ALL USER
export const GET_ALL_CONTENT_REQUEST = "GET_ALL_CONTENT_REQUEST";
export const GET_ALL_CONTENT_SUCCESS = "GET_ALL_CONTENT_SUCCESS";
export const GET_ALL_CONTENT_FAILURE = "GET_ALL_CONTENT_FAILURE";
//------------------------------------------------------------------//
