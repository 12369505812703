import { LayoutDashboard, Users, Clapperboard, MessageSquareQuote, LifeBuoy, Plane, School } from "lucide-react";
import Sidebar, { SidebarItem } from "./Sidebar";
import { useLocation } from "react-router-dom";
import { routesConfig } from "helper/Config";

const Side = () => {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  return (
    <>
      <Sidebar>
        <SidebarItem
          icon={
            <LayoutDashboard
              size={25}
              className={`bg-transparent ${pathname === routesConfig.dashboard.split("/")[1] ? "fill-white" : ""}`}
            />
          }
          text="Dashboard"
          // alert
          path="/dashboard"
          active={pathname === routesConfig.dashboard.split("/")[1] ? true : false}
        />
        <SidebarItem
          icon={
            <Users
              size={25}
              className={`bg-transparent ${
                pathname === routesConfig.users.split("/")[1] || pathname === routesConfig.userDetails.split("/")[1] ? "fill-white" : ""
              }`}
            />
          }
          text="Users"
          active={pathname === routesConfig.users.split("/")[1] || pathname === routesConfig.userDetails.split("/")[1] ? true : false}
          path="/users"
        />
        <SidebarItem
          icon={
            <Clapperboard
              size={25}
              className={`bg-transparent ${pathname === routesConfig.content.split("/")[1] ? "fill-white" : ""}`}
            />
          }
          text="Content"
          path="/content"
          // alert
          active={pathname === routesConfig.content.split("/")[1] ? true : false}
        />
        <SidebarItem
          icon={
            <MessageSquareQuote
              size={25}
              className={`bg-transparent ${pathname === routesConfig.feedbacks.split("/")[1] ? "fill-white" : ""}`}
            />
          }
          text="Feedbacks"
          path="/feedbacks"
          active={pathname === routesConfig.feedbacks.split("/")[1] ? true : false}
        />
        <SidebarItem
          icon={
            <LifeBuoy
              size={25}
              className={`bg-transparent ${
                pathname === routesConfig.reports.split("/")[1] || pathname === routesConfig.reportDetail.split("/")[1] ? "fill-white" : ""
              }`}
            />
          }
          text="Reports"
          path="/reports"
          active={pathname === routesConfig.reports.split("/")[1] || pathname === routesConfig.reportDetail.split("/")[1] ? true : false}
        />
        <SidebarItem
          icon={
            <Plane
              size={25}
              className={`bg-transparent ${pathname === routesConfig.flightSimGame.split("/")[1] ? "fill-white" : ""}`}
            />
          }
          text="Flight Sim Game"
          path="/flight-sim-game"
          active={pathname === routesConfig.flightSimGame.split("/")[1] ? true : false}
        />
        <SidebarItem
          icon={
            <School
              size={25}
              className={`bg-transparent ${pathname === routesConfig.homeAirport.split("/")[1] ? "fill-white" : ""}`}
            />
          }
          text="Favorite Airport"
          path="/home-airport"
          active={pathname === routesConfig.homeAirport.split("/")[1] ? true : false}
        />
      </Sidebar>
    </>
  );
};

export default Side;
