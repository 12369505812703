import { ConfigProvider, Table, theme } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

const TableWrapper = ({ columns, data, tableParams, rowSelection, handleTableChange, loading }) => {
  const [selectionType] = useState("checkbox");

  return (
    <div>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            // colorPrimary: "white",
          },
          components: {
            Table: {
              algorithm: true,
              colorText: "white",
              // colorBgBase: "#363636",
            },
          },
        }}>
        <AntTable
          rowKey={(record) => record.id}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          size="middle"
          pagination={tableParams.pagination}
          onChange={handleTableChange}
          // loading={loadingState}
          scroll={{ x: "100%" }}
        />
      </ConfigProvider>
    </div>
  );
};

export default TableWrapper;

export const AntTable = styled(Table)`
  .ant-table-tbody > tr {
    &:hover {
      td {
        background-color: #4f4f4f !important;
      }
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    background-color: #363636;
    border-bottom: none;
    border-right: 1px solid #9d9d9d;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #9d9d9d;
    text-align: center;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    &:last-child {
      border-right: none;
    }
  }

  .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background-color: #4f4f4f !important;
  }
`;
