import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EditButton,
  DeleteButton,
  Heading,
  RenderCell,
  TableWrapper,
  InputField,
  CustomCheckbox,
  SpinLoader,
  ModelWrapper,
  DateTimeFormat,
  ErrorInfoIcon,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import { listAllHomeAirport, createHomeAirport, updateHomeAirport, deleteHomeAirport } from "redux_manager/actions";
import { ButtonWrapper, ErrorPara, Form, FormBg, InputLabel, InputWrap } from "assets/styles";
import { homeAirportSchema } from "helper";
import { Autocomplete, AutocompleteOption, ListItemDecorator, ListItemContent, Typography } from "@mui/joy";
import { countries } from "data";

const HomeAirport = () => {
  const { loading, homeAirportList } = useSelector((state) => state.homeAirport);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedHomeAirportId, setSelectedHomeAirportId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryError, setCountryError] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(homeAirportSchema),
  });

  const onSubmit = async (values) => {
    if (!selectedCountry === null && !countries.find((c) => c.label === selectedCountry)) {
      setCountryError(true);
      return;
    } else if (selectedCountry === null) {
      setCountryError(true);
      return;
    } else setCountryError(false);

    try {
      const body = { name: values?.name, country: selectedCountry, is_active: isActive };

      // Update API
      if (isEdit) {
        dispatch(updateHomeAirport(body, selectedHomeAirportId, handleClose));
        return;
      }

      // Create API
      dispatch(createHomeAirport(body, handleClose));
      // Submit the form data here
    } catch (error) {
      console.log("🚀 ~ file: HomeAirport.jsx:28 ~ onSubmit ~ error:", error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    reset();
    setSelectedCountry(null); // Reset selected country
    setCountryError(false);
    setIsActive(true);
    setSelectedHomeAirportId(null);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    setSelectedHomeAirportId(id);
    const homeAirport = homeAirportList.find((x) => x.id === id);
    setValue("name", homeAirport?.name);
    setSelectedCountry(homeAirport?.country); // Set selected country
    setIsActive(homeAirport?.is_active);
    setIsOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteHomeAirport(id));
  };

  const handleSwalFire = async (id) => {
    const { value } = await Swal.fire({
      title: "Are you sure you want to delete?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef3e55",
      showCancelButton: true,
      customClass: { confirmButton: "!shadow-none box border-none" },
    });

    if (value) {
      handleDelete(id);
    }
  };

  const columns = [
    {
      title: "Favorite Airport ID",
      dataIndex: "index",
      key: "index",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value}
        />
      ),
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      className: "text-xl border-r border-font_color_1",
      width: 120,
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={value ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      className: "text-xl",

      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={<DateTimeFormat value={value} />}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      className: "text-xl",
      fixed: "right",
      width: 120,
      render: (value, record) => (
        <RenderCell
          loading={loading}
          content={
            <div className="flex items-center justify-center gap-4">
              <button onClick={() => handleEdit(value)}>
                <EditButton />
              </button>

              <button onClick={() => handleSwalFire(value)}>
                <DeleteButton />
              </button>
            </div>
          }
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const fetchHomeAirportApi = async () => {
    // API
    await dispatch(listAllHomeAirport());
  };

  useEffect(() => {
    fetchHomeAirportApi(); // Fetch initial data
  }, [homeAirportList?.length]);

  useEffect(() => {
    const length = homeAirportList?.length;
    setData(homeAirportList);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: length,
      },
    });
  }, [homeAirportList]);

  return (
    <div className="w-full p-16 text-white">
      <div className="flex flex-col gap-8">
        <div className="flex items-center gap-4">
          <Heading title={"Favorite Airport"} />
          <ButtonWrapper
            className="focus:outline-none"
            type="button"
            $size="1.125"
            $radius="0.5"
            $color="#FFF"
            $bg_color="#234DF0"
            onClick={() => setIsOpen(true)}>
            Add Favorite Airport
          </ButtonWrapper>

          {/* Model */}
          <ModelWrapper
            open={isOpen}
            onCancel={handleClose}
            title={`${isEdit ? "Edit" : "Add"} Favorite Airport`}
            size={25}>
            <FormBg
              $radius="0.5"
              $padding="1.5"
              className="dark:border dark:border-gray-700 #374151 !max-w-full">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <InputField
                  className={"!rounded-lg"}
                  label="Airport Name"
                  name="name"
                  type="text"
                  placeholder="Enter airport name"
                  register={register}
                  error={errors?.name}
                />
                {/* Select Country */}
                <InputWrap>
                  <InputLabel htmlFor={String("country")}>{"Country"}</InputLabel>
                  <CustomizeAutoComplete
                    id="country-select-demo"
                    className={
                      "!rounded-lg !bg-gray-700 !text-white !w-full !focus:ring-primary-600 !focus:border-primary-600  !dark:focus:ring-blue-500 !dark:focus:border-blue-500 !focus:outline-none"
                    }
                    placeholder="Select a country"
                    name="country"
                    sx={{ width: 300, "--Input-focusedThickness": "0" }}
                    options={countries}
                    autoHighlight
                    variant="plain"
                    value={selectedCountry ? countries.find((c) => c.label === selectedCountry) : null} // Set selected country
                    onChange={(event, newValue) => {
                      setSelectedCountry(newValue ? newValue.label : null);
                      setCountryError(newValue ? false : true);
                    }} // Update selected country
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <AutocompleteOption {...props}>
                        <ListItemDecorator>
                          <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt=""
                          />
                        </ListItemDecorator>
                        <ListItemContent sx={{ fontSize: "sm" }}>{option.label}</ListItemContent>
                      </AutocompleteOption>
                    )}
                  />
                  {countryError && (
                    <ErrorPara>
                      <span>
                        <ErrorInfoIcon />
                      </span>
                      <span>{"Please select a country!"}</span>
                    </ErrorPara>
                  )}
                </InputWrap>
                <CustomCheckbox
                  checkboxId={"name_active"}
                  label={"Active"}
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                  // color={"#868E96"}
                />
                <div className="flex justify-center">
                  <ButtonWrapper
                    className="focus:outline-none transition-all"
                    type="submit"
                    $color="#FFF"
                    $bg_color="#234DF0"
                    $size="1.125"
                    $radius="0.5">
                    {loading && <SpinLoader />}
                    {isEdit ? "Edit" : "Create"}
                  </ButtonWrapper>
                </div>
              </Form>
            </FormBg>
          </ModelWrapper>
        </div>

        {/* Table */}
        <div>
          <TableWrapper
            columns={columns}
            data={data}
            tableParams={tableParams}
            rowSelection={rowSelection}
            handleTableChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeAirport;

const CustomizeAutoComplete = styled(Autocomplete)`
  border-radius: ${({ $radius }) => ($radius ? `${$radius}rem` : "3.625rem")};
  padding: 0.6rem 1rem !important;
  border: 2px solid transparent !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  display: flex !important;
  align-items: center !important;

  input {
    &::placeholder {
      color: #ffffffb3 !important;
      opacity: 1;
    }
  }

  // button {
  //   &:hover {
  //     background-color: #234df0 !important;

  //     svg > path {
  //       fill: #ffffff !important;
  //     }
  //   }
  // }

  &:hover {
    border-color: #3b82f6 !important;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.07) !important;
  }

  &:focus-within {
    border-color: #3b82f6 !important;
    box-shadow: 0px 2px 8px 0px rgba(101, 163, 255, 0.17) !important;
  }
`;
