export const Logo = ({ fill }) => {
  return (
    <span>
      <svg
        width={145}
        height={27}
        viewBox="0 0 145 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          width={27}
          height={27}
          rx="13.5"
          fill="#234DF0"
        />
        <path
          d="M10.7133 20.8222C10.6871 20.8222 10.6586 20.8222 10.6323 20.82C10.3259 20.7959 10.0479 20.6405 9.86621 20.391L8.59004 18.642C8.5594 18.6004 8.52219 18.5632 8.4806 18.5326L6.60684 17.1535C6.35949 16.9696 6.20407 16.6916 6.18 16.3852C6.15811 16.0787 6.26755 15.7767 6.48645 15.5621C6.80823 15.2382 7.28542 15.1484 7.70351 15.3301L9.12196 15.9518L12.0092 12.8413C12.0596 12.7865 12.0595 12.723 12.0552 12.6902C12.0508 12.6574 12.0333 12.5983 11.9676 12.5589L7.00305 9.92993C6.68783 9.74168 6.49739 9.44617 6.45799 9.10907C6.4164 8.76978 6.53023 8.43925 6.77102 8.19846C7.00742 7.96205 7.34015 7.86136 7.66849 7.93579L14.9227 9.47244C14.9906 9.48557 15.0562 9.46587 15.1 9.4199L17.7027 6.65524C17.9829 6.35973 18.4513 6.1868 18.9898 6.17805C19.5414 6.16929 20.0624 6.34441 20.3601 6.64211C20.6578 6.93981 20.8307 7.45421 20.822 8.0124C20.8154 8.55307 20.6425 9.02151 20.3492 9.29951H20.347L17.5757 11.9109C17.5276 11.9569 17.5057 12.027 17.521 12.0904L19.189 19.43C19.2678 19.7584 19.1715 20.0999 18.9285 20.3406C18.6899 20.5814 18.3616 20.6953 18.0223 20.6558C17.6874 20.6164 17.3919 20.4282 17.2146 20.1393L14.439 15.0083C14.4105 14.9645 14.3514 14.9492 14.3186 14.9448C14.2857 14.9405 14.2244 14.9405 14.1697 14.9908L11.0658 17.8759L11.6852 19.2965C11.8691 19.7146 11.7772 20.1918 11.4554 20.5158C11.2584 20.7128 10.9913 20.8222 10.7133 20.8222Z"
          fill="white"
        />
        <path
          d="M37.3743 21.7316C36.1528 21.7316 34.9598 21.5828 33.7931 21.2851C32.6242 20.983 31.6939 20.6021 31 20.1468L32.4272 17.0823C33.0795 17.5025 33.8719 17.8484 34.8 18.1154C35.7325 18.3781 36.6475 18.5095 37.5406 18.5095C39.3443 18.5095 40.2462 18.0629 40.2462 17.1698C40.2462 16.7495 39.9966 16.4496 39.5019 16.268C39.0116 16.0819 38.2542 15.9265 37.2342 15.8039C36.0302 15.6178 35.0386 15.4077 34.2572 15.1735C33.4735 14.9349 32.7949 14.5146 32.2171 13.9126C31.6436 13.3129 31.359 12.457 31.359 11.3472C31.359 10.4147 31.626 9.58724 32.1645 8.86926C32.7008 8.15128 33.4823 7.59091 34.5111 7.18814C35.5443 6.78537 36.7614 6.58398 38.1623 6.58398C39.1999 6.58398 40.233 6.69781 41.2619 6.92546C42.295 7.15311 43.1487 7.46833 43.8273 7.87109L42.4001 10.9094C41.1086 10.1805 39.6967 9.81489 38.1623 9.81489C37.2517 9.81489 36.5687 9.94404 36.1134 10.2002C35.6581 10.4519 35.4305 10.778 35.4305 11.1808C35.4305 11.6361 35.6756 11.9557 36.166 12.1352C36.6607 12.3169 37.4465 12.4898 38.5213 12.6518C39.723 12.8576 40.7059 13.0721 41.472 13.2997C42.2359 13.5274 42.9014 13.9433 43.4683 14.5431C44.0331 15.145 44.3176 15.9834 44.3176 17.056C44.3176 17.9666 44.0418 18.7787 43.4946 19.4901C42.9517 20.2037 42.155 20.7553 41.1043 21.145C40.0579 21.5346 38.8146 21.7316 37.3743 21.7316Z"
          fill={fill ? fill : "#E6E6E6"}
        />
        <path
          d="M47.0786 6.8033H51.3427V21.5131H47.0786V6.8033ZM49.2063 4.75443C48.4226 4.75443 47.7878 4.52678 47.2975 4.07147C46.8072 3.61617 46.562 3.0536 46.562 2.38159C46.562 1.7052 46.8072 1.13826 47.2975 0.682956C47.7878 0.227652 48.4226 0 49.2063 0C49.9943 0 50.6335 0.218896 51.1238 0.656689C51.6141 1.09448 51.8593 1.64172 51.8593 2.29403C51.8593 3.00763 51.6141 3.59647 51.1238 4.06272C50.6335 4.52459 49.9943 4.75443 49.2063 4.75443Z"
          fill={fill ? fill : "#E6E6E6"}
        />
        <path
          d="M74.2767 6.58398C76.1154 6.58398 77.5777 7.12685 78.6634 8.21257C79.7491 9.2983 80.292 10.9247 80.292 13.0896V21.5127H76.0279V13.7463C76.0279 12.5861 75.7849 11.7193 75.3011 11.1458C74.8152 10.5679 74.1279 10.279 73.2348 10.279C72.23 10.279 71.4354 10.6029 70.8532 11.2509C70.2753 11.8988 69.9863 12.8597 69.9863 14.1315V21.5127H65.7222V13.7463C65.7222 11.4347 64.7897 10.279 62.9291 10.279C61.9485 10.279 61.1648 10.6029 60.5826 11.2509C59.9981 11.8988 59.707 12.8597 59.707 14.1315V21.5127H55.4429V6.80288H59.5143V8.50152C60.0616 7.88423 60.7314 7.41141 61.5194 7.08307C62.3118 6.75035 63.1852 6.58398 64.1374 6.58398C65.175 6.58398 66.1119 6.79194 66.9481 7.20565C67.7886 7.61499 68.4628 8.21038 68.9707 8.99184C69.5704 8.2279 70.3322 7.63469 71.2559 7.21441C72.1775 6.79412 73.1844 6.58398 74.2767 6.58398Z"
          fill={fill ? fill : "#E6E6E6"}
        />
        <path
          d="M88.9292 7.13597H92.6943V10.4107H89.0343V21.5131H84.7702V10.4107H82.5024V7.13597H84.7702V6.47928C84.7702 4.80472 85.2649 3.47602 86.2587 2.49536C87.2503 1.51033 88.649 1.01562 90.4528 1.01562C91.0941 1.01562 91.7005 1.08348 92.274 1.21701C92.8453 1.35273 93.3247 1.54754 93.7099 1.80365L92.5892 4.89447C92.0989 4.5508 91.5232 4.37787 90.8643 4.37787C89.5728 4.37787 88.9292 5.0871 88.9292 6.50555V7.13597Z"
          fill={fill ? fill : "#E6E6E6"}
        />
        <path
          d="M109.372 14.2103C109.372 14.2694 109.346 14.6547 109.293 15.3661H98.1644C98.3614 16.2767 98.8342 16.9991 99.5828 17.5288C100.329 18.0542 101.26 18.3168 102.376 18.3168C103.14 18.3168 103.816 18.203 104.407 17.9753C105.003 17.7477 105.554 17.3865 106.062 16.8896L108.33 19.35C106.947 20.9392 104.924 21.7316 102.262 21.7316C100.603 21.7316 99.1363 21.4076 97.8579 20.7597C96.584 20.1118 95.6011 19.2165 94.9072 18.0717C94.2177 16.9225 93.874 15.6178 93.874 14.1578C93.874 12.7218 94.2155 11.426 94.8985 10.2702C95.5814 9.11005 96.5183 8.20382 97.7091 7.55588C98.9043 6.90795 100.242 6.58398 101.719 6.58398C103.16 6.58398 104.462 6.89701 105.624 7.52086C106.791 8.14034 107.708 9.02687 108.374 10.1826C109.039 11.3384 109.372 12.6824 109.372 14.2103ZM101.746 9.81489C100.782 9.81489 99.9703 10.0907 99.3114 10.6379C98.6569 11.1808 98.2563 11.9251 98.1119 12.8707H105.353C105.206 11.9426 104.804 11.2027 104.145 10.6467C103.49 10.0929 102.691 9.81489 101.746 9.81489Z"
          fill={fill ? fill : "#E6E6E6"}
        />
        <path
          d="M126.757 14.2103C126.757 14.2694 126.731 14.6547 126.678 15.3661H115.55C115.747 16.2767 116.219 16.9991 116.968 17.5288C117.715 18.0542 118.645 18.3168 119.761 18.3168C120.525 18.3168 121.202 18.203 121.793 17.9753C122.388 17.7477 122.94 17.3865 123.447 16.8896L125.715 19.35C124.332 20.9392 122.309 21.7316 119.647 21.7316C117.988 21.7316 116.522 21.4076 115.243 20.7597C113.969 20.1118 112.986 19.2165 112.292 18.0717C111.603 16.9225 111.259 15.6178 111.259 14.1578C111.259 12.7218 111.601 11.426 112.284 10.2702C112.967 9.11005 113.904 8.20382 115.094 7.55588C116.29 6.90795 117.627 6.58398 119.105 6.58398C120.545 6.58398 121.847 6.89701 123.01 7.52086C124.176 8.14034 125.094 9.02687 125.759 10.1826C126.424 11.3384 126.757 12.6824 126.757 14.2103ZM119.131 9.81489C118.168 9.81489 117.356 10.0907 116.697 10.6379C116.042 11.1808 115.642 11.9251 115.497 12.8707H122.738C122.592 11.9426 122.189 11.2027 121.53 10.6467C120.875 10.0929 120.076 9.81489 119.131 9.81489Z"
          fill={fill ? fill : "#E6E6E6"}
        />
        <path
          d="M144.719 1.22559V21.5129H140.639V19.8143C139.582 21.0926 138.052 21.7318 136.051 21.7318C134.668 21.7318 133.416 21.4231 132.295 20.8037C131.174 20.1864 130.292 19.302 129.651 18.1506C129.014 17.0014 128.696 15.6706 128.696 14.158C128.696 12.6476 129.014 11.3189 129.651 10.1741C130.292 9.02486 131.174 8.14052 132.295 7.52104C133.416 6.89719 134.668 6.58417 136.051 6.58417C137.929 6.58417 139.398 7.17738 140.455 8.36161V1.22559H144.719ZM136.787 18.2295C137.859 18.2295 138.752 17.8617 139.466 17.1262C140.177 16.3863 140.534 15.3969 140.534 14.158C140.534 12.9212 140.177 11.934 139.466 11.1985C138.752 10.4586 137.859 10.0865 136.787 10.0865C135.694 10.0865 134.793 10.4586 134.081 11.1985C133.368 11.934 133.013 12.9212 133.013 14.158C133.013 15.3969 133.368 16.3863 134.081 17.1262C134.793 17.8617 135.694 18.2295 136.787 18.2295Z"
          fill={fill ? fill : "#E6E6E6"}
        />
      </svg>
    </span>
  );
};

export const Logout = ({ className }) => {
  return (
    <span className={`${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-log-out">
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
        <polyline points="16 17 21 12 16 7" />
        <line
          x1="21"
          x2="9"
          y1="12"
          y2="12"
        />
      </svg>
    </span>
  );
};

export const SendIcon = () => {
  return (
    <span>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.16667 5.2668L13.2417 2.90846C16.4167 1.85013 18.1417 3.58346 17.0917 6.75846L14.7333 13.8335C13.15 18.5918 10.55 18.5918 8.96667 13.8335L8.26667 11.7335L6.16667 11.0335C1.40833 9.45013 1.40833 6.85846 6.16667 5.2668Z"
          stroke="#234DF0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const EmailIcon = () => {
  return (
    <span className="flex">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="white"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17 21H7C4 21 2 19.5 2 16V9C2 5.5 4 4 7 4H17C20 4 22 5.5 22 9V16C22 19.5 20 21 17 21Z"
          stroke="#01030E"
          strokeWidth="1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 9.5L13.87 12C12.84 12.82 11.15 12.82 10.12 12L7 9.5"
          stroke="#01030E"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const ErrorInfoIcon = () => {
  return (
    <span className="flex">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 14.6666C11.6667 14.6666 14.6667 11.6666 14.6667 7.99998C14.6667 4.33331 11.6667 1.33331 8 1.33331C4.33333 1.33331 1.33333 4.33331 1.33333 7.99998C1.33333 11.6666 4.33333 14.6666 8 14.6666Z"
          stroke="#FA5252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.33331V8.66665"
          stroke="#FA5252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99634 10.6667H8.00233"
          stroke="#FA5252"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const PasswordIcon = () => {
  return (
    <span className="flex">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="white"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 10.5V8.5C6 5.19 7 2.5 12 2.5C17 2.5 18 5.19 18 8.5V10.5"
          stroke="#01030E"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 22.5H7C3 22.5 2 21.5 2 17.5V15.5C2 11.5 3 10.5 7 10.5H17C21 10.5 22 11.5 22 15.5V17.5C22 21.5 21 22.5 17 22.5Z"
          stroke="#01030E"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9965 16.5H16.0054"
          stroke="#292D32"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9955 16.5H12.0045"
          stroke="#292D32"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99451 16.5H8.00349"
          stroke="#292D32"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const LeftArrowIcon = () => {
  return (
    <span className="ml-0_62 flex">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.8225 4.44727L15.375 8.99977L10.8225 13.5523"
          stroke="#234DF0"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.625 9H15.2475"
          stroke="#234DF0"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export const ORLinesLeft = () => {
  return (
    <span>
      <svg
        width="175"
        height="1"
        viewBox="0 0 175 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          opacity="0.2"
          width="174.5"
          height="1"
          transform="matrix(-1 0 0 1 174.5 0)"
          fill="url(#paint0_linear_2194_15471)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2194_15471"
            x1="0"
            y1="0"
            x2="130.214"
            y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#ADB5BD" />
            <stop
              offset="1"
              stopColor="#ADB5BD"
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};

export const ORLinesRight = () => {
  return (
    <span>
      <svg
        width="175"
        height="1"
        viewBox="0 0 175 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          opacity="0.2"
          x="0.5"
          width="174.5"
          height="1"
          fill="url(#paint0_linear_2194_15473)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2194_15473"
            x1="0.5"
            y1="0"
            x2="130.714"
            y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#ADB5BD" />
            <stop
              offset="1"
              stopColor="#ADB5BD"
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};

export const HeaderLogo = () => {
  return (
    <span>
      <svg
        width="145"
        height="28"
        viewBox="0 0 145 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          y="0.5"
          width="27"
          height="27"
          rx="13.5"
          fill="#234DF0"
        />
        <path
          d="M10.7138 21.3222C10.6876 21.3222 10.6591 21.3222 10.6328 21.32C10.3264 21.2959 10.0484 21.1405 9.8667 20.891L8.59053 19.142C8.55989 19.1004 8.52267 19.0632 8.48108 19.0326L6.60733 17.6535C6.35998 17.4696 6.20456 17.1916 6.18048 16.8852C6.15859 16.5787 6.26804 16.2767 6.48694 16.0621C6.80872 15.7382 7.28591 15.6484 7.704 15.8301L9.12245 16.4518L12.0097 13.3413C12.06 13.2865 12.06 13.223 12.0557 13.1902C12.0513 13.1574 12.0338 13.0983 11.9681 13.0589L7.00353 10.4299C6.68832 10.2417 6.49788 9.94617 6.45848 9.60907C6.41689 9.26978 6.53072 8.93925 6.7715 8.69846C7.00791 8.46205 7.34063 8.36136 7.66898 8.43579L14.9232 9.97244C14.9911 9.98557 15.0567 9.96587 15.1005 9.9199L17.7032 7.15524C17.9834 6.85973 18.4518 6.6868 18.9903 6.67805C19.5419 6.66929 20.0629 6.84441 20.3606 7.14211C20.6583 7.43981 20.8312 7.95421 20.8225 8.5124C20.8159 9.05307 20.643 9.52151 20.3496 9.79951H20.3475L17.5762 12.4109C17.5281 12.4569 17.5062 12.527 17.5215 12.5904L19.1895 19.93C19.2683 20.2584 19.172 20.5999 18.929 20.8406C18.6904 21.0814 18.3621 21.1953 18.0228 21.1558C17.6879 21.1164 17.3924 20.9282 17.215 20.6393L14.4394 15.5083C14.411 15.4645 14.3519 15.4492 14.319 15.4448C14.2862 15.4405 14.2249 15.4405 14.1702 15.4908L11.0662 18.3759L11.6857 19.7965C11.8696 20.2146 11.7777 20.6918 11.4559 21.0158C11.2589 21.2128 10.9918 21.3222 10.7138 21.3222Z"
          fill="white"
        />
        <path
          d="M37.3743 22.2316C36.1528 22.2316 34.9598 22.0828 33.7931 21.7851C32.6242 21.483 31.6939 21.1021 31 20.6468L32.4272 17.5823C33.0795 18.0025 33.8719 18.3484 34.8 18.6154C35.7325 18.8781 36.6475 19.0095 37.5406 19.0095C39.3443 19.0095 40.2462 18.5629 40.2462 17.6698C40.2462 17.2495 39.9966 16.9496 39.5019 16.768C39.0116 16.5819 38.2542 16.4265 37.2342 16.3039C36.0302 16.1178 35.0386 15.9077 34.2572 15.6735C33.4735 15.4349 32.7949 15.0146 32.2171 14.4126C31.6436 13.8129 31.359 12.957 31.359 11.8472C31.359 10.9147 31.626 10.0872 32.1645 9.36926C32.7008 8.65128 33.4823 8.09091 34.5111 7.68814C35.5443 7.28537 36.7614 7.08398 38.1623 7.08398C39.1999 7.08398 40.233 7.19781 41.2619 7.42546C42.295 7.65311 43.1487 7.96833 43.8273 8.37109L42.4001 11.4094C41.1086 10.6805 39.6967 10.3149 38.1623 10.3149C37.2517 10.3149 36.5687 10.444 36.1134 10.7002C35.6581 10.9519 35.4305 11.278 35.4305 11.6808C35.4305 12.1361 35.6756 12.4557 36.166 12.6352C36.6607 12.8169 37.4465 12.9898 38.5213 13.1518C39.723 13.3576 40.7059 13.5721 41.472 13.7997C42.2359 14.0274 42.9014 14.4433 43.4683 15.0431C44.0331 15.645 44.3176 16.4834 44.3176 17.556C44.3176 18.4666 44.0418 19.2787 43.4946 19.9901C42.9517 20.7037 42.155 21.2553 41.1043 21.645C40.0579 22.0346 38.8146 22.2316 37.3743 22.2316Z"
          fill="#162B5A"
        />
        <path
          d="M47.0781 7.3033H51.3422V22.0131H47.0781V7.3033ZM49.2058 5.25443C48.4221 5.25443 47.7873 5.02678 47.297 4.57147C46.8067 4.11617 46.5615 3.5536 46.5615 2.88159C46.5615 2.2052 46.8067 1.63826 47.297 1.18296C47.7873 0.727652 48.4221 0.5 49.2058 0.5C49.9938 0.5 50.633 0.718896 51.1233 1.15669C51.6136 1.59448 51.8588 2.14172 51.8588 2.79403C51.8588 3.50763 51.6136 4.09647 51.1233 4.56272C50.633 5.02459 49.9938 5.25443 49.2058 5.25443Z"
          fill="#162B5A"
        />
        <path
          d="M74.2762 7.08398C76.1149 7.08398 77.5772 7.62685 78.6629 8.71257C79.7486 9.7983 80.2915 11.4247 80.2915 13.5896V22.0127H76.0274V14.2463C76.0274 13.0861 75.7844 12.2193 75.3007 11.6458C74.8147 11.0679 74.1274 10.779 73.2343 10.779C72.2295 10.779 71.4349 11.1029 70.8527 11.7509C70.2748 12.3988 69.9859 13.3597 69.9859 14.6315V22.0127H65.7218V14.2463C65.7218 11.9347 64.7893 10.779 62.9286 10.779C61.948 10.779 61.1643 11.1029 60.5821 11.7509C59.9976 12.3988 59.7065 13.3597 59.7065 14.6315V22.0127H55.4424V7.30288H59.5139V9.00152C60.0611 8.38423 60.7309 7.91141 61.5189 7.58307C62.3113 7.25035 63.1847 7.08398 64.1369 7.08398C65.1745 7.08398 66.1114 7.29194 66.9476 7.70565C67.7881 8.11499 68.4623 8.71038 68.9702 9.49184C69.5699 8.7279 70.3317 8.13469 71.2555 7.71441C72.177 7.29412 73.1839 7.08398 74.2762 7.08398Z"
          fill="#162B5A"
        />
        <path
          d="M88.9287 7.63597H92.6938V10.9107H89.0338V22.0131H84.7697V10.9107H82.502V7.63597H84.7697V6.97928C84.7697 5.30472 85.2644 3.97602 86.2582 2.99536C87.2498 2.01033 88.6486 1.51562 90.4523 1.51562C91.0936 1.51562 91.7 1.58348 92.2735 1.71701C92.8448 1.85273 93.3242 2.04754 93.7094 2.30365L92.5887 5.39447C92.0984 5.0508 91.5227 4.87787 90.8638 4.87787C89.5723 4.87787 88.9287 5.5871 88.9287 7.00555V7.63597Z"
          fill="#162B5A"
        />
        <path
          d="M109.372 14.7103C109.372 14.7694 109.346 15.1547 109.293 15.8661H98.1644C98.3614 16.7767 98.8342 17.4991 99.5828 18.0288C100.329 18.5542 101.26 18.8168 102.376 18.8168C103.14 18.8168 103.816 18.703 104.407 18.4753C105.003 18.2477 105.554 17.8865 106.062 17.3896L108.33 19.85C106.947 21.4392 104.924 22.2316 102.262 22.2316C100.603 22.2316 99.1363 21.9076 97.8579 21.2597C96.584 20.6118 95.6011 19.7165 94.9072 18.5717C94.2177 17.4225 93.874 16.1178 93.874 14.6578C93.874 13.2218 94.2155 11.926 94.8985 10.7702C95.5814 9.61005 96.5183 8.70382 97.7091 8.05588C98.9043 7.40795 100.242 7.08398 101.719 7.08398C103.16 7.08398 104.462 7.39701 105.624 8.02086C106.791 8.64034 107.708 9.52687 108.374 10.6826C109.039 11.8384 109.372 13.1824 109.372 14.7103ZM101.746 10.3149C100.782 10.3149 99.9703 10.5907 99.3114 11.1379C98.6569 11.6808 98.2563 12.4251 98.1119 13.3707H105.353C105.206 12.4426 104.804 11.7027 104.145 11.1467C103.49 10.5929 102.691 10.3149 101.746 10.3149Z"
          fill="#162B5A"
        />
        <path
          d="M126.758 14.7103C126.758 14.7694 126.731 15.1547 126.679 15.8661H115.55C115.747 16.7767 116.22 17.4991 116.969 18.0288C117.715 18.5542 118.645 18.8168 119.762 18.8168C120.526 18.8168 121.202 18.703 121.793 18.4753C122.388 18.2477 122.94 17.8865 123.448 17.3896L125.716 19.85C124.332 21.4392 122.31 22.2316 119.648 22.2316C117.989 22.2316 116.522 21.9076 115.244 21.2597C113.97 20.6118 112.987 19.7165 112.293 18.5717C111.603 17.4225 111.26 16.1178 111.26 14.6578C111.26 13.2218 111.601 11.926 112.284 10.7702C112.967 9.61005 113.904 8.70382 115.095 8.05588C116.29 7.40795 117.627 7.08398 119.105 7.08398C120.545 7.08398 121.848 7.39701 123.01 8.02086C124.177 8.64034 125.094 9.52687 125.759 10.6826C126.425 11.8384 126.758 13.1824 126.758 14.7103ZM119.131 10.3149C118.168 10.3149 117.356 10.5907 116.697 11.1379C116.043 11.6808 115.642 12.4251 115.498 13.3707H122.739C122.592 12.4426 122.189 11.7027 121.53 11.1467C120.876 10.5929 120.077 10.3149 119.131 10.3149Z"
          fill="#162B5A"
        />
        <path
          d="M144.719 1.72559V22.0129H140.639V20.3143C139.582 21.5926 138.052 22.2318 136.051 22.2318C134.668 22.2318 133.416 21.9231 132.295 21.3037C131.174 20.6864 130.292 19.802 129.651 18.6506C129.014 17.5014 128.696 16.1706 128.696 14.658C128.696 13.1476 129.014 11.8189 129.651 10.6741C130.292 9.52486 131.174 8.64052 132.295 8.02104C133.416 7.39719 134.668 7.08417 136.051 7.08417C137.929 7.08417 139.398 7.67738 140.455 8.86161V1.72559H144.719ZM136.787 18.7295C137.859 18.7295 138.752 18.3617 139.466 17.6262C140.177 16.8863 140.534 15.8969 140.534 14.658C140.534 13.4212 140.177 12.434 139.466 11.6985C138.752 10.9586 137.859 10.5865 136.787 10.5865C135.694 10.5865 134.793 10.9586 134.081 11.6985C133.368 12.434 133.013 13.4212 133.013 14.658C133.013 15.8969 133.368 16.8863 134.081 17.6262C134.793 18.3617 135.694 18.7295 136.787 18.7295Z"
          fill="#162B5A"
        />
      </svg>
    </span>
  );
};

export const FooterLogo = () => {
  return (
    <span>
      <svg
        width="145"
        height="27"
        viewBox="0 0 145 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          width="27"
          height="27"
          rx="13.5"
          fill="#234DF0"
        />
        <path
          d="M10.7138 20.8222C10.6876 20.8222 10.6591 20.8222 10.6328 20.82C10.3264 20.7959 10.0484 20.6405 9.8667 20.391L8.59053 18.642C8.55989 18.6004 8.52267 18.5632 8.48108 18.5326L6.60733 17.1535C6.35998 16.9696 6.20456 16.6916 6.18048 16.3852C6.15859 16.0787 6.26804 15.7767 6.48694 15.5621C6.80872 15.2382 7.28591 15.1484 7.704 15.3301L9.12245 15.9518L12.0097 12.8413C12.06 12.7865 12.06 12.723 12.0557 12.6902C12.0513 12.6574 12.0338 12.5983 11.9681 12.5589L7.00353 9.92993C6.68832 9.74168 6.49788 9.44617 6.45848 9.10907C6.41689 8.76978 6.53072 8.43925 6.7715 8.19846C7.00791 7.96205 7.34063 7.86136 7.66898 7.93579L14.9232 9.47244C14.9911 9.48557 15.0567 9.46587 15.1005 9.4199L17.7032 6.65524C17.9834 6.35973 18.4518 6.1868 18.9903 6.17805C19.5419 6.16929 20.0629 6.34441 20.3606 6.64211C20.6583 6.93981 20.8312 7.45421 20.8225 8.0124C20.8159 8.55307 20.643 9.02151 20.3496 9.29951H20.3475L17.5762 11.9109C17.5281 11.9569 17.5062 12.027 17.5215 12.0904L19.1895 19.43C19.2683 19.7584 19.172 20.0999 18.929 20.3406C18.6904 20.5814 18.3621 20.6953 18.0228 20.6558C17.6879 20.6164 17.3924 20.4282 17.215 20.1393L14.4394 15.0083C14.411 14.9645 14.3519 14.9492 14.319 14.9448C14.2862 14.9405 14.2249 14.9405 14.1702 14.9908L11.0662 17.8759L11.6857 19.2965C11.8696 19.7146 11.7777 20.1918 11.4559 20.5158C11.2589 20.7128 10.9918 20.8222 10.7138 20.8222Z"
          fill="white"
        />
        <path
          d="M37.3743 21.7316C36.1528 21.7316 34.9598 21.5828 33.7931 21.2851C32.6242 20.983 31.6939 20.6021 31 20.1468L32.4272 17.0823C33.0795 17.5025 33.8719 17.8484 34.8 18.1154C35.7325 18.3781 36.6475 18.5095 37.5406 18.5095C39.3443 18.5095 40.2462 18.0629 40.2462 17.1698C40.2462 16.7495 39.9966 16.4496 39.5019 16.268C39.0116 16.0819 38.2542 15.9265 37.2342 15.8039C36.0302 15.6178 35.0386 15.4077 34.2572 15.1735C33.4735 14.9349 32.7949 14.5146 32.2171 13.9126C31.6436 13.3129 31.359 12.457 31.359 11.3472C31.359 10.4147 31.626 9.58724 32.1645 8.86926C32.7008 8.15128 33.4823 7.59091 34.5111 7.18814C35.5443 6.78537 36.7614 6.58398 38.1623 6.58398C39.1999 6.58398 40.233 6.69781 41.2619 6.92546C42.295 7.15311 43.1487 7.46833 43.8273 7.87109L42.4001 10.9094C41.1086 10.1805 39.6967 9.81489 38.1623 9.81489C37.2517 9.81489 36.5687 9.94404 36.1134 10.2002C35.6581 10.4519 35.4305 10.778 35.4305 11.1808C35.4305 11.6361 35.6756 11.9557 36.166 12.1352C36.6607 12.3169 37.4465 12.4898 38.5213 12.6518C39.723 12.8576 40.7059 13.0721 41.472 13.2997C42.2359 13.5274 42.9014 13.9433 43.4683 14.5431C44.0331 15.145 44.3176 15.9834 44.3176 17.056C44.3176 17.9666 44.0418 18.7787 43.4946 19.4901C42.9517 20.2037 42.155 20.7553 41.1043 21.145C40.0579 21.5346 38.8146 21.7316 37.3743 21.7316Z"
          fill="white"
        />
        <path
          d="M47.0781 6.8033H51.3422V21.5131H47.0781V6.8033ZM49.2058 4.75443C48.4221 4.75443 47.7873 4.52678 47.297 4.07147C46.8067 3.61617 46.5615 3.0536 46.5615 2.38159C46.5615 1.7052 46.8067 1.13826 47.297 0.682956C47.7873 0.227652 48.4221 0 49.2058 0C49.9938 0 50.633 0.218896 51.1233 0.656689C51.6136 1.09448 51.8588 1.64172 51.8588 2.29403C51.8588 3.00763 51.6136 3.59647 51.1233 4.06272C50.633 4.52459 49.9938 4.75443 49.2058 4.75443Z"
          fill="white"
        />
        <path
          d="M74.2762 6.58398C76.1149 6.58398 77.5772 7.12685 78.6629 8.21257C79.7486 9.2983 80.2915 10.9247 80.2915 13.0896V21.5127H76.0274V13.7463C76.0274 12.5861 75.7844 11.7193 75.3007 11.1458C74.8147 10.5679 74.1274 10.279 73.2343 10.279C72.2295 10.279 71.4349 10.6029 70.8527 11.2509C70.2748 11.8988 69.9859 12.8597 69.9859 14.1315V21.5127H65.7218V13.7463C65.7218 11.4347 64.7893 10.279 62.9286 10.279C61.948 10.279 61.1643 10.6029 60.5821 11.2509C59.9976 11.8988 59.7065 12.8597 59.7065 14.1315V21.5127H55.4424V6.80288H59.5139V8.50152C60.0611 7.88423 60.7309 7.41141 61.5189 7.08307C62.3113 6.75035 63.1847 6.58398 64.1369 6.58398C65.1745 6.58398 66.1114 6.79194 66.9476 7.20565C67.7881 7.61499 68.4623 8.21038 68.9702 8.99184C69.5699 8.2279 70.3317 7.63469 71.2555 7.21441C72.177 6.79412 73.1839 6.58398 74.2762 6.58398Z"
          fill="white"
        />
        <path
          d="M88.9287 7.13597H92.6938V10.4107H89.0338V21.5131H84.7697V10.4107H82.502V7.13597H84.7697V6.47928C84.7697 4.80472 85.2644 3.47602 86.2582 2.49536C87.2498 1.51033 88.6486 1.01562 90.4523 1.01562C91.0936 1.01562 91.7 1.08348 92.2735 1.21701C92.8448 1.35273 93.3242 1.54754 93.7094 1.80365L92.5887 4.89447C92.0984 4.5508 91.5227 4.37787 90.8638 4.37787C89.5723 4.37787 88.9287 5.0871 88.9287 6.50555V7.13597Z"
          fill="white"
        />
        <path
          d="M109.372 14.2103C109.372 14.2694 109.346 14.6547 109.293 15.3661H98.1644C98.3614 16.2767 98.8342 16.9991 99.5828 17.5288C100.329 18.0542 101.26 18.3168 102.376 18.3168C103.14 18.3168 103.816 18.203 104.407 17.9753C105.003 17.7477 105.554 17.3865 106.062 16.8896L108.33 19.35C106.947 20.9392 104.924 21.7316 102.262 21.7316C100.603 21.7316 99.1363 21.4076 97.8579 20.7597C96.584 20.1118 95.6011 19.2165 94.9072 18.0717C94.2177 16.9225 93.874 15.6178 93.874 14.1578C93.874 12.7218 94.2155 11.426 94.8985 10.2702C95.5814 9.11005 96.5183 8.20382 97.7091 7.55588C98.9043 6.90795 100.242 6.58398 101.719 6.58398C103.16 6.58398 104.462 6.89701 105.624 7.52086C106.791 8.14034 107.708 9.02687 108.374 10.1826C109.039 11.3384 109.372 12.6824 109.372 14.2103ZM101.746 9.81489C100.782 9.81489 99.9703 10.0907 99.3114 10.6379C98.6569 11.1808 98.2563 11.9251 98.1119 12.8707H105.353C105.206 11.9426 104.804 11.2027 104.145 10.6467C103.49 10.0929 102.691 9.81489 101.746 9.81489Z"
          fill="white"
        />
        <path
          d="M126.758 14.2103C126.758 14.2694 126.731 14.6547 126.679 15.3661H115.55C115.747 16.2767 116.22 16.9991 116.969 17.5288C117.715 18.0542 118.645 18.3168 119.762 18.3168C120.526 18.3168 121.202 18.203 121.793 17.9753C122.388 17.7477 122.94 17.3865 123.448 16.8896L125.716 19.35C124.332 20.9392 122.31 21.7316 119.648 21.7316C117.989 21.7316 116.522 21.4076 115.244 20.7597C113.97 20.1118 112.987 19.2165 112.293 18.0717C111.603 16.9225 111.26 15.6178 111.26 14.1578C111.26 12.7218 111.601 11.426 112.284 10.2702C112.967 9.11005 113.904 8.20382 115.095 7.55588C116.29 6.90795 117.627 6.58398 119.105 6.58398C120.545 6.58398 121.848 6.89701 123.01 7.52086C124.177 8.14034 125.094 9.02687 125.759 10.1826C126.425 11.3384 126.758 12.6824 126.758 14.2103ZM119.131 9.81489C118.168 9.81489 117.356 10.0907 116.697 10.6379C116.043 11.1808 115.642 11.9251 115.498 12.8707H122.739C122.592 11.9426 122.189 11.2027 121.53 10.6467C120.876 10.0929 120.077 9.81489 119.131 9.81489Z"
          fill="white"
        />
        <path
          d="M144.719 1.22656V21.5139H140.639V19.8152C139.582 21.0936 138.052 21.7328 136.051 21.7328C134.668 21.7328 133.416 21.4241 132.295 20.8046C131.174 20.1874 130.292 19.303 129.651 18.1516C129.014 17.0024 128.696 15.6715 128.696 14.159C128.696 12.6486 129.014 11.3199 129.651 10.175C130.292 9.02584 131.174 8.1415 132.295 7.52202C133.416 6.89817 134.668 6.58514 136.051 6.58514C137.929 6.58514 139.398 7.17835 140.455 8.36258V1.22656H144.719ZM136.787 18.2304C137.859 18.2304 138.752 17.8627 139.466 17.1272C140.177 16.3873 140.534 15.3979 140.534 14.159C140.534 12.9222 140.177 11.935 139.466 11.1995C138.752 10.4596 137.859 10.0875 136.787 10.0875C135.694 10.0875 134.793 10.4596 134.081 11.1995C133.368 11.935 133.013 12.9222 133.013 14.159C133.013 15.3979 133.368 16.3873 134.081 17.1272C134.793 17.8627 135.694 18.2304 136.787 18.2304Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

export const FaceBookIcon = () => {
  return (
    <span>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22 11C22 4.92486 17.0751 0 11 0C4.92486 0 0 4.92486 0 11C0 16.4903 4.02252 21.0412 9.28125 21.8664V14.1797H6.48828V11H9.28125V8.57656C9.28125 5.81969 10.9235 4.29688 13.4361 4.29688C14.6392 4.29688 15.8984 4.51172 15.8984 4.51172V7.21875H14.5114C13.145 7.21875 12.7188 8.06674 12.7188 8.9375V11H15.7695L15.2818 14.1797H12.7188V21.8664C17.9775 21.0412 22 16.4903 22 11Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

export const InstaIcon = () => {
  return (
    <span>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.8405 1.83398H7.15884C3.82217 1.83398 1.83301 3.82315 1.83301 7.15982V14.8323C1.83301 18.1782 3.82217 20.1673 7.15884 20.1673H14.8313C18.168 20.1673 20.1572 18.1782 20.1572 14.8415V7.15982C20.1663 3.82315 18.1772 1.83398 14.8405 1.83398ZM10.9997 14.5573C9.03801 14.5573 7.44301 12.9623 7.44301 11.0007C7.44301 9.03898 9.03801 7.44398 10.9997 7.44398C12.9613 7.44398 14.5563 9.03898 14.5563 11.0007C14.5563 12.9623 12.9613 14.5573 10.9997 14.5573ZM16.4263 6.30732C16.3805 6.41732 16.3163 6.51815 16.2338 6.60982C16.1422 6.69232 16.0413 6.75648 15.9313 6.80232C15.8213 6.84815 15.7022 6.87565 15.583 6.87565C15.3355 6.87565 15.1063 6.78398 14.9322 6.60982C14.8497 6.51815 14.7855 6.41732 14.7397 6.30732C14.6938 6.19732 14.6663 6.07815 14.6663 5.95898C14.6663 5.83982 14.6938 5.72065 14.7397 5.61065C14.7855 5.49148 14.8497 5.39982 14.9322 5.30815C15.143 5.09732 15.4638 4.99648 15.7572 5.06065C15.8213 5.06982 15.8763 5.08815 15.9313 5.11565C15.9863 5.13398 16.0413 5.16148 16.0963 5.19815C16.1422 5.22565 16.188 5.27148 16.2338 5.30815C16.3163 5.39982 16.3805 5.49148 16.4263 5.61065C16.4722 5.72065 16.4997 5.83982 16.4997 5.95898C16.4997 6.07815 16.4722 6.19732 16.4263 6.30732Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

export const XIcon = () => {
  return (
    <span>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.92098 19.9382C15.2208 19.9382 19.7618 13.0602 19.7618 7.09738C19.7618 6.90402 19.7575 6.70636 19.7489 6.513C20.6322 5.87418 21.3945 5.0829 22 4.17636C21.1773 4.54239 20.3038 4.78144 19.4094 4.88535C20.3512 4.32084 21.0563 3.43402 21.3941 2.38929C20.5082 2.91435 19.5393 3.28473 18.529 3.48456C17.8483 2.76127 16.9482 2.28236 15.968 2.12188C14.9879 1.96141 13.9821 2.1283 13.1063 2.59676C12.2304 3.06522 11.5333 3.80915 11.1227 4.71354C10.7121 5.61793 10.6108 6.6324 10.8346 7.60011C9.04062 7.51009 7.28561 7.04407 5.68332 6.23226C4.08102 5.42046 2.66722 4.28099 1.53355 2.88773C0.957366 3.88114 0.781052 5.05668 1.04044 6.17542C1.29984 7.29416 1.97547 8.27217 2.93004 8.91066C2.21341 8.88791 1.51248 8.69496 0.885156 8.34777V8.40363C0.884514 9.44614 1.24492 10.4567 1.90512 11.2635C2.56531 12.0704 3.48455 12.6237 4.50656 12.8294C3.84272 13.011 3.14598 13.0375 2.47027 12.9068C2.75867 13.8033 3.31978 14.5875 4.07529 15.1498C4.8308 15.7121 5.74303 16.0246 6.68465 16.0435C5.08606 17.2992 3.11133 17.9803 1.07852 17.9771C0.718013 17.9765 0.357866 17.9544 0 17.9109C2.06512 19.2358 4.4674 19.9394 6.92098 19.9382Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

export const LinkedInIcon = () => {
  return (
    <span>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.3715 0H1.62422C0.726172 0 0 0.708984 0 1.58555V20.4102C0 21.2867 0.726172 22 1.62422 22H20.3715C21.2695 22 22 21.2867 22 20.4145V1.58555C22 0.708984 21.2695 0 20.3715 0ZM6.52695 18.7473H3.26133V8.2457H6.52695V18.7473ZM4.89414 6.81484C3.8457 6.81484 2.99922 5.96836 2.99922 4.92422C2.99922 3.88008 3.8457 3.03359 4.89414 3.03359C5.93828 3.03359 6.78477 3.88008 6.78477 4.92422C6.78477 5.96406 5.93828 6.81484 4.89414 6.81484ZM18.7473 18.7473H15.4859V13.6426C15.4859 12.4266 15.4645 10.8582 13.7887 10.8582C12.0914 10.8582 11.8336 12.1859 11.8336 13.5566V18.7473H8.57656V8.2457H11.7047V9.68086H11.7477C12.1816 8.85586 13.2473 7.98359 14.8328 7.98359C18.1371 7.98359 18.7473 10.1578 18.7473 12.9852V18.7473Z"
          fill="white"
        />
      </svg>
    </span>
  );
};
