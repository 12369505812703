import Checkbox from "@mui/joy/Checkbox";
import Box from "@mui/joy/Box";
import { CheckboxLabel } from "assets/styles";

const CustomCheckbox = ({ label, checked, onChange, checkboxId, color }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Checkbox
        id={checkboxId}
        checked={checked}
        onChange={onChange}
        sx={{
          "& .MuiCheckbox-checkbox.Mui-checked": {
            bgcolor: "#234DF0",
          },
          "& .MuiSvgIcon-root": {
            color: "#FFF",
          },
        }}
      />
      <CheckboxLabel
        $color={color}
        htmlFor={checkboxId}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </Box>
  );
};
export default CustomCheckbox;
