import React from "react";
import { ErrorPara, Input, InputDiv, InputLabel, InputWrap } from "assets/styles";
import { ErrorInfoIcon } from "./icon";

const InputField = ({ label, name, type, placeholder, register, error, fieldIcon, className }) => (
  <InputWrap>
    <InputLabel htmlFor={String(name)}>{label}</InputLabel>
    <InputDiv
      // tabIndex={1}
      className={`bg-gray-700 w-full focus:ring-primary-600 focus:border-primary-600  dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none ${
        className || ""
      }`}>
      {fieldIcon}
      <Input
        className="w-full !text-white bg-inherit focus:outline-none"
        type={type}
        placeholder={placeholder}
        {...register(name)}
      />
    </InputDiv>
    {error && (
      <ErrorPara>
        <span>
          <ErrorInfoIcon />
        </span>
        <span>{error && error.message}</span>
      </ErrorPara>
    )}
  </InputWrap>
);

export default InputField;
