import styled from "styled-components";
import { Link } from "react-router-dom";
import { Table } from "antd";

export const NavLink = styled(Link)`
  color: ${({ $color }) => ($color ? $color : "rgb(1 3 14 / 40%)")};
  font-size: 1rem;
  font-weight: 450;
  line-height: normal;

  &:hover {
    color: ${({ $h_color }) => ($h_color ? $h_color : "#01030e")};
  }
`;

export const ButtonWrapper = styled.button`
  font-size: ${({ $size }) => ($size ? `${$size}rem` : "1.25rem")};
  font-weight: ${({ $weight }) => ($weight ? $weight : 450)};
  line-height: normal;
  background-color: ${({ $bg_color }) => ($bg_color ? $bg_color : "transparent")};
  border-radius: ${({ $radius }) => ($radius ? `${$radius}rem` : "3.75rem")};
  padding: 0.9375rem 1rem;
  border: 0.125rem solid ${({ $border_color }) => ($border_color ? $border_color : "transparent")};
  color: ${({ $color }) => ($color ? $color : "#234DF0")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => ($width ? $width : "fit-content")};
  height: fit-content;

  &:hover {
    border-color: #3b82f6;
    box-shadow: 0px 2px 8px 0px rgba(101, 163, 255, 0.17);
  }

  &:focus-within {
    border-color: #3b82f6;
    box-shadow: 0px 2px 8px 0px rgba(101, 163, 255, 0.17);
  }
`;

export const FormBg = styled.div`
  border-radius: ${({ $radius }) => ($radius ? `${$radius}rem` : "1.5rem")};
  background: #1f2937;
  padding: ${({ $padding }) => ($padding ? `${$padding}rem` : "1.875rem")};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 28rem;
`;

export const LinkWrapper = styled(Link)`
  font-size: ${({ $size }) => ($size ? `${$size}rem` : "0.875rem")};
  font-weight: ${({ $weight }) => ($weight ? $weight : 450)};
  line-height: normal;
  background-color: ${({ $bg_color }) => ($bg_color ? $bg_color : "transparent")};
  border-radius: ${({ $radius }) => ($radius ? `${$radius}rem` : "3.75rem")};
  padding: 0.9375rem 1rem;
  border: 0.0625rem solid ${({ $border_color }) => ($border_color ? $border_color : "#234DF0")};
  color: ${({ $color }) => ($color ? $color : "#234DF0")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => ($width ? $width : "fit-content")};
  height: fit-content;
`;

export const Para0_875 = styled.p`
  color: ${({ $color }) => ($color ? $color : "#7c7e85")};
  font-size: 0.875rem;
  font-weight: ${({ $weight }) => ($weight ? $weight : 400)};
  line-height: 1.125rem;
  letter-spacing: -0.00875rem;
`;

export const Para1 = styled.p`
  color: ${({ $color }) => ($color ? $color : "#6B7075")};
  font-size: 1rem;
  font-weight: ${({ $weight }) => ($weight ? $weight : 400)};
  line-height: 1.25rem;
`;

export const Para1_25 = styled.p`
  color: ${({ $color }) => ($color ? $color : "#234DF0")};
  font-size: 1.25rem;
  font-weight: ${({ $weight }) => ($weight ? $weight : 700)};
  line-height: normal;
`;

export const Para1_125 = styled.p`
  color: ${({ $color }) => ($color ? $color : "#7C7E85")};
  font-size: 1.125rem;
  font-weight: ${({ $weight }) => ($weight ? $weight : 400)};
  line-height: 1.75rem;
`;

export const Head2 = styled.h6`
  color: ${({ $color }) => ($color ? $color : "#01030E")};
  font-size: ${({ $size }) => ($size ? $size : "2rem")};
  font-weight: ${({ $weight }) => ($weight ? $weight : 700)};
  line-height: normal;
`;

export const Head3_125 = styled.h2`
  color: ${({ $color }) => ($color ? $color : "#01030E")};
  font-size: 3.125rem;
  font-weight: ${({ $weight }) => ($weight ? $weight : 700)};
  line-height: normal;
`;

export const Head4_375 = styled.h1`
  color: ${({ $color }) => ($color ? $color : "#FFF")};
  font-size: 4.375rem;
  font-weight: ${({ $weight }) => ($weight ? $weight : 700)};
  line-height: normal;
`;

export const InputWrapper = styled.div`
  display: flex;
  padding: 0.9375rem 1.1875rem;
  gap: 0.75rem;
  border-radius: 3.625rem;
  border: 1px solid #e7eaf2;
  background: #fff;
`;

export const InputFiled = styled.input``;

// Form
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputWrap = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
`;

export const InputDiv = styled.div`
  border-radius: ${({ $radius }) => ($radius ? `${$radius}rem` : "3.625rem")};
  padding: 0.8125rem 1rem;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  gap: 0.75rem;

  &:hover {
    border-color: #3b82f6;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.07);
  }

  &:focus-within {
    border-color: #3b82f6;
    box-shadow: 0px 2px 8px 0px rgba(101, 163, 255, 0.17);
  }
`;

export const CheckboxLabel = styled.label`
  color: ${({ $color }) => ($color ? $color : "#FFFFFF")};
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
`;

export const ErrorPara = styled.p`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  color: #fa5252;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: normal;
`;

export const Input = styled.input`
  color: #01030e;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;

  &::placeholder {
    color: #ffffffb3;
  }
`;
