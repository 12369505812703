/* The code block you provided is defining a set of constants for report-related actions in a JavaScript
application. Each constant represents a specific action that can be dispatched in the application. */

// CONTENT CONSTANTS //

//------------------------------------------------------------------//
// GET ALL REPORT
export const GET_ALL_REPORT_REQUEST = "GET_ALL_REPORT_REQUEST";
export const GET_ALL_REPORT_SUCCESS = "GET_ALL_REPORT_SUCCESS";
export const GET_ALL_REPORT_FAILURE = "GET_ALL_REPORT_FAILURE";
//------------------------------------------------------------------//
