import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const SpinLoader = ({ size, color }) => {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          className="mr-2 transition-all"
          style={{
            fontSize: size || 20,
            color: color || "#ffffff",
          }}
          spin
        />
      }
    />
  );
};

export default SpinLoader;
