import React from "react";
import styled from "styled-components";

const Overview = ({ Icon, title, count, percent, subTitle, bgColor }) => {
  return (
    // <div className="lg:w-1/4 md:w-2/4 sm:w-full">
    <div className="">
      <div className="flex justify-center items-center gap-4 text-white mb-8">
        <span>
          <Icon size={30} />
        </span>
        <p className="font-medium text-2xl">{title}</p>
      </div>
      <BgWrapper
        $color={bgColor}
        className={`flex justify-center items-center rounded-[80px] h-[320px]`}>
        <div>
          <h1 className="text-center font-bold text-5xl mb-4">{count || <p>&nbsp;</p>}</h1>
          {/* {percent ? (
            <p className="text-3xl text-center font-medium my-2">{percent}</p>
          ) : (
            <p className="text-3xl text-center font-medium my-2">&nbsp;</p>
          )} */}
          <p className="text-center font-semibold text-3xl">{subTitle}</p>
        </div>
      </BgWrapper>
    </div>
  );
};

export default Overview;

// Para10Tag
export const BgWrapper = styled.div`
  background-color: ${({ $color }) => $color};
`;
