import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

export const flightSchema = yup.object().shape({
  game_name: yup.string().required("Game name is required!"),
});

export const homeAirportSchema = yup.object().shape({
  name: yup.string().required("Airport name is required!"),
  // country: yup.string().required("Please select a country!"),
});
