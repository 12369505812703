/* The code block you provided is defining a set of constants for flight-sim-game related actions in a JavaScript
application. Each constant represents a specific action that can be dispatched in the application. */

// FLIGHT-SIM-GAME CONSTANTS //

// GET ALL FLIGHT_SIM_GAME
export const GET_ALL_FLIGHT_SIM_GAME_REQUEST = "GET_ALL_FLIGHT_SIM_GAME_REQUEST";
export const GET_ALL_FLIGHT_SIM_GAME_SUCCESS = "GET_ALL_FLIGHT_SIM_GAME_SUCCESS";
export const GET_ALL_FLIGHT_SIM_GAME_FAILURE = "GET_ALL_FLIGHT_SIM_GAME_FAILURE";

// CREATE FLIGHT_SIM_GAME
export const CREATE_FLIGHT_SIM_GAME = "CREATE_FLIGHT_SIM_GAME";

// UPDATE FLIGHT_SIM_GAME
export const UPDATE_FLIGHT_SIM_GAME = "UPDATE_FLIGHT_SIM_GAME";

// DELETE FLIGHT_SIM_GAME
export const DELETE_FLIGHT_SIM_GAME = "DELETE_FLIGHT_SIM_GAME";
