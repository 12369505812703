import React from "react";
import { Auth } from "helper/Auth";
import { routesConfig } from "helper/Config";
import { useNavigate } from "react-router-dom";

const Homepage = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (Auth.getToken()) {
      navigate(routesConfig.dashboard);
    } else {
      navigate(routesConfig.login);
    }
  }, []);
  return <div></div>;
};

export default Homepage;
