/* The code block you provided is defining a set of constants for user-related actions in a JavaScript
application. Each constant represents a specific action that can be dispatched in the application. */

// USER CONSTANTS //

//------------------------------------------------------------------//
// GET ALL USER
export const GET_ALL_USER_REQUEST = "GET_ALL_USER_REQUEST";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const GET_ALL_USER_FAILURE = "GET_ALL_USER_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET SINGLE USER
export const GET_SINGLE_USER_REQUEST = "GET_SINGLE_USER_REQUEST";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAILURE = "GET_SINGLE_USER_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET SINGLE USER ACTIVITY
export const GET_SINGLE_USER_ACTIVITY_REQUEST = "GET_SINGLE_USER_ACTIVITY_REQUEST";
export const GET_SINGLE_USER_ACTIVITY_SUCCESS = "GET_SINGLE_USER_ACTIVITY_SUCCESS";
export const GET_SINGLE_USER_ACTIVITY_FAILURE = "GET_SINGLE_USER_ACTIVITY_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// Search USER
export const SEARCH_USER_REQUEST = "SEARCH_USER_REQUEST";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE";
//------------------------------------------------------------------//
