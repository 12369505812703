import { APIHeaderCors, ContentAPI, UserAPI, getHeaders } from "helper/API";
import { toastMessage } from "helper/Config";
import {
  GET_ALL_USER_REQUEST,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FAILURE,
  GET_SINGLE_USER_ACTIVITY_REQUEST,
  GET_SINGLE_USER_ACTIVITY_SUCCESS,
  GET_SINGLE_USER_ACTIVITY_FAILURE,
} from "redux_manager/constants";
import { ErrorToast } from "helper/Toast";

export const listAllUsers = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_USER_REQUEST });

      // API
      const response = await UserAPI.get("/", { headers });

      const usersList = [];
      response?.data?.forEach((x) => {
        usersList.push({
          ...x,
          id: x?._id,
        });
      });

      dispatch({
        type: GET_ALL_USER_SUCCESS,
        payload: usersList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_USER_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const listAllUserActivity = (userId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: GET_SINGLE_USER_ACTIVITY_REQUEST });

      // API
      const response = await ContentAPI.get(`/user-post/admin/${userId}`, { headers });

      const usersActivityList = [];
      response?.data?.forEach((x, index) => {
        usersActivityList.push({
          ...x,
          id: x?._id,
          index: index + 1,
        });
      });

      dispatch({
        type: GET_SINGLE_USER_ACTIVITY_SUCCESS,
        payload: usersActivityList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_SINGLE_USER_ACTIVITY_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};

export const listSearchUsers = (query) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {};
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_USER_REQUEST });

      // API
      const response = await ContentAPI.post(`/search-feed?searchQuery=${query}`, body, { headers });

      const usersList = [];
      response?.data?.users?.forEach((x) => {
        usersList.push({
          ...x,
          id: x?._id,
        });
      });

      dispatch({
        type: GET_ALL_USER_SUCCESS,
        payload: usersList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_USER_FAILURE,
        payload: error?.message,
      });

      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    console.error("An additional error occurred:", catchError);
  });
};
